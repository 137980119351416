import tokens from '@/tokens/tokens.json'
import { get } from 'lodash'
import { FigmaTypography } from '..'

export const figmaFontGroupProperties = [
  'fontFamily',
  'fontWeight',
  'lineHeight',
  'fontSize',
  'letterSpacing',
  'paragraphSpacing',
  'paragraphIndent',
  'textCase',
  'textDecoration',
] as const

export type FigmaFontGroup = Record<
  typeof figmaFontGroupProperties[number],
  string
>

const FALL_BACK_FONT_GROUP = {
  fontFamily: 'Arial',
  fontWeight: '400',
  lineHeight: '1.5',
  fontSize: '16px',
  letterSpacing: '0',
  paragraphSpacing: '0',
  paragraphIndent: '0',
  textCase: 'none',
  textDecoration: 'none',
}

type FigmaFontSizeGroup = Record<string, FigmaFontGroup>

export type FigmaFontWeightGroup = {
  'display 2xl': FigmaFontSizeGroup
  'display xl': FigmaFontSizeGroup
  'display lg': FigmaFontSizeGroup
  'display md': FigmaFontSizeGroup
  'display sm': FigmaFontSizeGroup
  'display xs': FigmaFontSizeGroup
  'text xl': FigmaFontSizeGroup
  'text lg': FigmaFontSizeGroup
  'text md': FigmaFontSizeGroup
  'text sm': FigmaFontSizeGroup
  'text xs': FigmaFontSizeGroup
}

const buildFallbackFontGroup = (): FigmaFontWeightGroup => {
  return {
    'display 2xl': {},
    'display xl': {},
    'display lg': {},
    'display md': {},
    'display sm': {},
    'display xs': {},
    'text xl': {},
    'text lg': {},
    'text md': {},
    'text sm': {},
    'text xs': {},
  }
}

const buildFontWeightGroup = (fontGroupName: string): FigmaFontWeightGroup => {
  const fontGroup = { ...buildFallbackFontGroup() }
  const fontGroupTokens = tokens.global[fontGroupName]
  Object.keys(fontGroupTokens).forEach((fontWeight) => {
    if (fontGroup.hasOwnProperty(fontWeight)) {
      Object.keys(fontGroupTokens[fontWeight]).forEach((fontSize) => {
        if (!fontGroup[fontWeight].hasOwnProperty(fontSize)) {
          fontGroup[fontWeight][fontSize] = { ...FALL_BACK_FONT_GROUP }
        }
        fontGroup[fontWeight][fontSize as keyof FigmaFontSizeGroup].fontFamily =
          get(
            FigmaTypography,
            `${fontGroupTokens[fontWeight][fontSize].value.fontFamily
              .replace(/{/g, '')
              .replace(/}/g, '')}`
          )
        fontGroup[fontWeight][fontSize as keyof FigmaFontSizeGroup].fontWeight =
          get(
            FigmaTypography,
            `${fontGroupTokens[fontWeight][fontSize].value.fontWeight
              .replace(/{/g, '')
              .replace(/}/g, '')}`
          )
        fontGroup[fontWeight][fontSize as keyof FigmaFontSizeGroup].lineHeight =
          get(
            FigmaTypography,
            `${fontGroupTokens[fontWeight][fontSize].value.lineHeight
              .replace(/{/g, '')
              .replace(/}/g, '')}`
          )
        fontGroup[fontWeight][fontSize as keyof FigmaFontSizeGroup].fontSize =
          get(
            FigmaTypography,
            `${fontGroupTokens[fontWeight][fontSize].value.fontSize
              .replace(/{/g, '')
              .replace(/}/g, '')}`
          )
        fontGroup[fontWeight][
          fontSize as keyof FigmaFontSizeGroup
        ].letterSpacing = get(
          FigmaTypography,
          `${fontGroupTokens[fontWeight][fontSize].value.letterSpacing
            .replace(/{/g, '')
            .replace(/}/g, '')}`
        )
        fontGroup[fontWeight][
          fontSize as keyof FigmaFontSizeGroup
        ].paragraphSpacing = get(
          FigmaTypography,
          `${fontGroupTokens[fontWeight][fontSize].value.paragraphSpacing
            .replace(/{/g, '')
            .replace(/}/g, '')}`
        )
        fontGroup[fontWeight][
          fontSize as keyof FigmaFontSizeGroup
        ].paragraphIndent = get(
          FigmaTypography,
          `${fontGroupTokens[fontWeight][fontSize].value.paragraphIndent
            .replace(/{/g, '')
            .replace(/}/g, '')}`
        )
        fontGroup[fontWeight][fontSize as keyof FigmaFontSizeGroup].textCase =
          get(
            FigmaTypography,
            `${fontGroupTokens[fontWeight][fontSize].value.textCase
              .replace(/{/g, '')
              .replace(/}/g, '')}`
          )
        fontGroup[fontWeight][
          fontSize as keyof FigmaFontSizeGroup
        ].textDecoration = get(
          FigmaTypography,
          `${fontGroupTokens[fontWeight][fontSize].value.textDecoration
            .replace(/{/g, '')
            .replace(/}/g, '')}`
        )
      })
    }
  })
  return fontGroup
}

export const getFontGroup = (fontName: string) => buildFontWeightGroup(fontName)
