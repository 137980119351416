import { NextPageContext } from 'next'
// NB: important to use the full absolute import '@/utils/redirect' so that this is mocked in stories/tests as expected
import { RedirectParams, redirect } from '@/utils/redirect'

// Throwing RedirectError can be a nice way to ensure we stop execution of code on redirection
// by treating redirection as an exception it greatly simplifies return types of functions

export class RedirectError extends Error {
  name = 'Redirect'
  ctx?: NextPageContext
  nextUrl = ''

  setRedirection({ ctx, nextUrl }: RedirectParams): this {
    this.ctx = ctx
    this.nextUrl = nextUrl
    return this
  }

  doRedirect(): void {
    redirect(this)
  }
}

export function isRedirection<T>(
  error: T | RedirectError
): error is RedirectError {
  return (error as RedirectError)?.name === 'Redirect'
}
