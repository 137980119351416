import { Footer as BaseFooter } from '@/components/layout/Footer'
import { Fragment, FunctionComponent } from 'react'
import styled from '@/utils/styled'
import Grid from '@mui/material/Grid'
import { Logo } from '@/components/layout/Logo'

const Footer = styled(BaseFooter)`
  color: #e0e4ea;

  a {
    color: #e0e4ea;
  }

  & .content {
    border-top: none;
    margin: 4rem 0;
    text-align: center;
  }
`

const NoAuthContainer = styled('div')`
  max-width: 960px;
  margin: auto;
`

const LogoContainer = styled('div')`
  max-width: 150px;
  margin: 0 auto 2rem;
  text-align: center;
`

export const NoAuthLayout: FunctionComponent = ({ children }) => {
  return (
    <Fragment>
      <NoAuthContainer>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <LogoContainer>
              <Logo color="white" />
            </LogoContainer>
            {children}
            <Footer />
          </Grid>
        </Grid>
      </NoAuthContainer>
    </Fragment>
  )
}
