const v2Identifiers = ['/v2/', '/my-details', '/hr-services']

/**
 *
 * @param activeUrl can be either a fully-qualified URL like https://app.myhr.works/v2/people
 * or a relative URL like /hr-services or /people/person/123
 * @returns true if the activeUrl is a v2 URL
 */
export const isV2Url = (activeUrl?: string): boolean =>
  activeUrl
    ? v2Identifiers.some((identifier) => activeUrl.includes(identifier))
    : false
export const convertToV2Url = (isV2: boolean, url?: string): string =>
  url ? (isV2 && !isV2Url(url) ? `/v2${url}` : url) : ''
