import { createValues, FigmaValues } from './createValues'

// To create a new typography variable, follow the steps below:
// 1. Define a constant for the fallback value of the new typography property. This will be used when the desired value is not available.
//    For example: const FALLBACK_NEW_PROPERTY = 'default_value';
//
// 2. Add the new property to the Typography type:
//    newProperty: FigmaValues
//
// 3. Add the new property to the FigmaTypography object, using the createValues function:
//    newProperty: createValues(FALLBACK_NEW_PROPERTY, 'newProperty')
//
// Note: Replace 'newProperty' and 'default_value' with the actual property name and default value respectively.
// The 'newPropery' name can be found in the generated tokens.json file. 'src/tokens/tokens.json'

const FALLBACK_LINE_HEIGHT = '0rem' // An obscure Line height to tell us when we are using the wrong height
const FALL_BACK_FONT_FAMILY = 'Arial' // An obscure Font height to tell us when we are using the wrong height
const FALLBACK_FONT_SIZE = '999rem' // An obscure font size to tell us when we are using the wrong size
const FALLBACK_FONT_WEIGHT = '400' // A default font weight to use when the desired weight is not available
const FALLBACK_LETTER_SPACING = '0' // A default letter spacing to use when the desired spacing is not available
const FALLBACK_PARAGRAPH_SPACING = '0rem' // A default paragraph spacing to use when the desired spacing is not available
const FALLBACK_PARAGRAPH_INDENT = '0rem' // A default paragraph indent to use when the desired indent is not available
const FALLBACK_TEXT_CASE = 'none' // A default text case to use when the desired case is not available
const FALLBACK_TEXT_DECORATION = 'none' // A default text decoration to use when the desired decoration is not available

type Typography = {
  fontFamilies: FigmaValues
  lineHeights: FigmaValues
  fontSize: FigmaValues
  fontWeights: FigmaValues
  letterSpacing: FigmaValues
  paragraphSpacing: FigmaValues
  paragraphIndent: FigmaValues
  textCase: FigmaValues
  textDecoration: FigmaValues
}

export const FigmaTypography: Typography = {
  fontFamilies: createValues(FALL_BACK_FONT_FAMILY, 'fontFamilies'),
  fontSize: createValues(FALLBACK_FONT_SIZE, 'fontSize', 'px'),
  lineHeights: createValues(FALLBACK_LINE_HEIGHT, 'lineHeights', 'px'),
  fontWeights: createValues(FALLBACK_FONT_WEIGHT, 'fontWeights'),
  letterSpacing: createValues(FALLBACK_LETTER_SPACING, 'letterSpacing'),
  paragraphSpacing: createValues(
    FALLBACK_PARAGRAPH_SPACING,
    'paragraphSpacing'
  ),
  paragraphIndent: createValues(FALLBACK_PARAGRAPH_INDENT, 'paragraphIndent'),
  textCase: createValues(FALLBACK_TEXT_CASE, 'textCase'),
  textDecoration: createValues(FALLBACK_TEXT_DECORATION, 'textDecoration'),
}
