import { RefObject, useEffect, useRef } from 'react'

/**
 * Used to prevent state updates after an async function.
 * ```
 * const mounted = useMounted()
 *
 * useEffect(() => {
 *   someFunction()
 *   .then(() => {
 *     if (mounted.current) {
 *       // Update state.
 *     }
 *   })
 *   .catch(() => {
 *     if (mounted.current) {
 *       // Update state.
 *     }
 *   })
 * }, [])
 * ```
 */
export function useMounted(): RefObject<boolean> {
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  return mounted
}
