import https from 'https'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { NextPageContext } from 'next'

/**
 * Isomorphic (SSR or client side)
 *
 * HTTP request libraries
 */

interface Params {
  ctx?: NextPageContext
  url: string
  options?: AxiosRequestConfig
}

const isDevelopment = process.env.NODE_ENV === 'development'
const isomorphicUrl = ({ ctx, url }: Params) => {
  return `${ctx?.req ? process.env.BASE_URL : ''}${url}`
}
const isomorphicHeaders = (ctx): Record<string, string> => {
  const cookie = ctx?.req?.headers?.cookie || ''
  if (typeof cookie === 'string' && cookie) {
    return { cookie }
  }
  return {}
}

/**
 * Troubleshooting:
 *
 * If you forget to pass ctx through to api methods we
 * cannot allow the development certificate to be accepted.
 *
 * And you see the error "unable to verify the first certificate"
 *
 * @param ctx
 */
const isomorphicHttpsAgent = (ctx?: NextPageContext) =>
  ctx?.req
    ? new https.Agent({
        rejectUnauthorized: !isDevelopment,
      })
    : undefined
const isomorphicOptions = ({
  ctx,
  options = {},
}: Params): AxiosRequestConfig => {
  return {
    ...options,
    withCredentials: true,
    httpsAgent: isomorphicHttpsAgent(ctx),
    headers: {
      ...isomorphicHeaders(ctx),
      ...options.headers,
    },
  }
}

/**
 * GET (Read) request
 *
 * isomorphic (SSR or client side)
 *
 * @param params
 * Params requires:
 *   - url - string of the api path
 *
 * Optionally:
 *   - ctx - NextPageContext if you have it (required for SSR)
 *   - options - AxiosRequestConfig options
 */
export function get<ResponseBody>(
  params: Params
): Promise<AxiosResponse<ResponseBody>> {
  return axios.get<ResponseBody>(
    isomorphicUrl(params),
    isomorphicOptions(params)
  )
}

/**
 * POST (Create) request
 *
 * isomorphic (SSR or client side)
 *
 * @param params
 * Params requires:
 *   - url - string of the api path
 *
 * Optionally:
 *   - ctx - NextPageContext if you have it (required for SSR)
 *   - options - AxiosRequestConfig options
 *
 * @param data the data for creation.
 */
export function post<Data, ResponseBody>(
  params: Params,
  data: Data
): Promise<AxiosResponse<ResponseBody>> {
  return axios.post<Data, AxiosResponse<ResponseBody>>(
    isomorphicUrl(params),
    data,
    isomorphicOptions(params)
  )
}

/**
 * PUT (Update) request
 *
 * isomorphic (SSR or client side)
 *
 * @param params
 * Params requires:
 *   - url - string of the api path
 *
 * Optionally:
 *   - ctx - NextPageContext if you have it (required for SSR)
 *   - options - AxiosRequestConfig options
 *
 * @param data data for update
 */
export function put<Data, ResponseBody>(
  params: Params,
  data: Data
): Promise<AxiosResponse<ResponseBody>> {
  return axios.put<Data, AxiosResponse<ResponseBody>>(
    isomorphicUrl(params),
    data,
    isomorphicOptions(params)
  )
}

/**
 * DELETE (Delete) request
 *
 * isomorphic (SSR or client side)
 *
 * @param params
 * Params requires:
 *   - url - string of the api path
 *
 * Optionally:
 *   - ctx - NextPageContext if you have it (required for SSR)
 *   - options - AxiosRequestConfig options
 */
export function del<ResponseBody>(
  params: Params
): Promise<AxiosResponse<ResponseBody>> {
  return axios.delete<ResponseBody>(
    isomorphicUrl(params),
    isomorphicOptions(params)
  )
}
