export interface MyHRColors {
  blue: string
  navy: string
  cyan: string
  red: string
  green: string
  purple: string
  orange: string
  black: string
  grey: string
  neutral: string
  white: string
}

type MyHRPaletteColor = {
  dark: string
  main: string
  mid: string
  light: string
}

type MyHRPalette = {
  blue: MyHRPaletteColor
  cyan: MyHRPaletteColor
  red: MyHRPaletteColor
  green: MyHRPaletteColor
  purple: MyHRPaletteColor
  orange: MyHRPaletteColor
  grey: MyHRPaletteColor
  neutral: MyHRPaletteColor
}

// Extend type definitions so our custom pallet items are available
declare module '@mui/material/styles/createPalette' {
  interface CommonColors extends MyHRColors {
    neutral: string
    muted: string
  }
  interface Palette {
    info: PaletteColor
    success: PaletteColor
    warning: PaletteColor
    border: PaletteColor & { accent: string }
    shades: {
      blue: MyHRPaletteColor
      cyan: MyHRPaletteColor
      red: MyHRPaletteColor
      green: MyHRPaletteColor
      purple: MyHRPaletteColor
      grey: MyHRPaletteColor
      orange: MyHRPaletteColor
      neutral: MyHRPaletteColor
    }
  }
  interface TypeBackground {
    main: string
    light: string
    dark: string
  }
  interface TypeText {
    success: string
    warning: string
  }
}

/**
 * Notes/Rules:
 *  - All black, grey, and neutral colours use hue 227 (sourced from brand Navy hue).
 *  - All colours have a saturation of 100%, except for Blue/Purple with 77%.
 *  - All 'dark' variations (.dark) have a luminosity of 40%, except for Green/Cyan with 23%.
 *  - All 'main' variations (.main) have a luminosity of 56%, except for Green/Cyan with 33%.
 *  - All 'mid' variations (.mid) have a luminosity of 77%, except for Green/Cyan with 67%.
 *  - All 'light' variations (.light) have a luminosity of 92%, except for Green/Cyan with 85%.
 */

const myhrColors: MyHRColors = {
  navy: '#0a184b', //     hsl(227,  76%, 17%)
  blue: '#384fe5', //     hsl(232,  77%, 56%)
  cyan: '#5ff', //        hsl(161, 100%, 33%)
  red: '#ff2066', //      hsl(341, 100%, 56%)
  green: '#00a873', //    hsl(161, 100%, 33%) - exact opposite hue of red
  purple: '#a539e5', //   hsl(278,  77%, 56%)
  orange: '#ff711f', //   hsl(22,  100%, 56%)
  black: '#121316', //    hsl(227,  10%,  8%)
  grey: '#6a6f81', //     hsl(227,  10%, 46%)
  neutral: '#e2e3e9', //  hsl(227,  15%, 90%)
  white: '#fff',
}

const palette: MyHRPalette = {
  blue: {
    dark: '#172cb5',
    main: myhrColors.blue,
    mid: '#97a3f2',
    light: '#dbdffa',
  },
  cyan: {
    dark: '#007575',
    main: '#00a8a8',
    mid: myhrColors.cyan,
    light: '#b3ffff',
  },
  red: {
    dark: '#cc0041',
    main: myhrColors.red,
    mid: '#ff8aaf',
    light: '#ffd6e3',
  },
  green: {
    dark: '#007550',
    main: myhrColors.green,
    mid: '#57ffca',
    light: '#b3ffe7',
  },
  purple: {
    dark: '#7b17b5',
    main: myhrColors.purple,
    mid: '#d097f2',
    light: '#efdbfa',
  },
  orange: {
    dark: '#cc4b00',
    main: myhrColors.orange,
    mid: '#ffb58a',
    light: '#ffe5d6',
  },
  grey: {
    dark: '#3e414c', // hsl(227, 10%, 27%)
    main: myhrColors.grey, // hsl(227, 10%, 46%)
    mid: '',
    light: '#9da1af', // hsl(227, 10%, 65%)
  },
  neutral: {
    dark: '#bec1ca', // hsl(227, 10%, 77%)
    main: myhrColors.neutral, // hsl(227, 15%, 90%)
    mid: '#f0f1f5', // hsl(227, 20%, 95%)
    light: '#f9f9fb', // hsl(227, 20%, 98%)
  },
}

export default {
  common: {
    ...myhrColors,
  },
  text: {
    primary: myhrColors.black,
    secondary: palette.grey.main,
    disabled: palette.grey.light,
    success: palette.green.dark,
    warning: palette.red.dark,
  },
  background: {
    ...palette.neutral,
    paper: myhrColors.white,
  },
  border: {
    main: palette.neutral.dark,
    light: palette.neutral.main,
    dark: palette.grey.light,
    accent: myhrColors.cyan,
  },
  // Primary button
  primary: {
    ...palette.blue,
    contrastText: myhrColors.white,
  },
  // Secondary button
  secondary: {
    main: palette.grey.main,
    light: palette.neutral.main,
    dark: palette.grey.dark,
    contrastText: myhrColors.white,
  },
  // Info notification
  info: {
    ...palette.cyan,
    contrastText: myhrColors.white,
  },
  // Success notification
  success: {
    ...palette.green,
    contrastText: myhrColors.white,
  },
  // Warning notification
  warning: {
    ...palette.red,
    contrastText: myhrColors.white,
  },
  // General colour shades
  shades: palette,
}
