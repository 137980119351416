import { useState } from 'react'
import { useScript } from './useScript'
export const useAskUs = (): void => {
  const secondsToCacheLoaderFor = 5 * 60
  const coefficent = 1000 * secondsToCacheLoaderFor
  const [cacheBust] = useState(
    Math.round(new Date().getTime() / coefficent) * coefficent
  )
  useScript(`/ask-us.js?v${cacheBust}`)
}
