// Values pulled from the member project basic-settings.scss/myhr-colors.scss that we needed for the styles below
// @TODO work out how to incorporate in main theme
export const basicTheme = {
  palette: {
    blue: '#384fe5',
    cyan: '#5ff',
    black: '#10141a',
    white: '#fff',
    inputBackground: '#fefefe',
    inputBlue: '#6486db',
    grayBorder: 'rgb(210, 220, 234)',
    red: '#ff2066',
  },
  fontWeight: {
    body: 400,
    heavy: 500,
    normal: 400,
    light: 300,
  },
  borderRadius: '4px',
  cyanBoxShadow: 'rgba(77,222,223,.5)',
}
