import { MyHR } from '@/types/MyHR'
import { PermissionKey } from '@/types/Permission'

/**
 * Does the currently logged in user have permission either by role or permission granted.
 *
 * If permissionKey not provided then we are checking for role is owner or admin other wise denying
 *
 * user.role = 'owner' OR user.role = 'admin' have all permissions.
 */
export function hasOneOfPermissions(
  myhr: MyHR,
  permissionKeys?: PermissionKey[],
  allowManager = false
): boolean {
  const isLoggedInUser = !!(
    myhr.isAuthenticated &&
    myhr.user &&
    myhr.permissions
  )
  if (!isLoggedInUser) {
    return false
  }
  if (myhr.user.role === 'person') {
    return false
  }
  if (
    (allowManager && myhr.user.role === 'manager') ||
    myhr.user.role === 'owner' ||
    myhr.user.role === 'admin'
  ) {
    return true
  }
  if (!permissionKeys || permissionKeys.length <= 0) {
    // Not allowed by role and we are not checking a particular permission so not allowed
    return false
  }
  // Only managers left at this point but we might add others in future with permissions
  const permissions = myhr.permissions.filter(({ key }) =>
    permissionKeys.find((permissionKey) => key === permissionKey)
  )
  if (permissions.length <= 0) {
    return false
  }
  const hasSomePermission = permissions.some((permission) => {
    return myhr.user.permissions.find(
      (permissionId) => permissionId === permission.id
    )
  })
  if (hasSomePermission) {
    return true
  }
  return false
}
