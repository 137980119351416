import { isV2Url } from '@/utils/isV2Url'
import { useCurrentUrl } from '@/utils/useCurrentUrl'

/**
 * Return true if the current window.location starts with /v2/
 */
export const useIsV2 = (): boolean => {
  const currentUrl = useCurrentUrl()
  return isV2Url(currentUrl)
}
