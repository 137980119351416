import { interFont } from '../GlobalStyle'
import { basicTheme } from './styled/basicTheme'
import { classBasedBasicFormStyles } from './styled/styled-component-css-helpers'

/**
 * Using styled-jsx approach to ensure css ordering is correct with other global styles via imports
 * We want global components to be last to allow overrides from those sheets
 *
 * NB: @emotion/react Global came before those external imported sheets
 *
 * @See https://nextjs.org/blog/styling-next-with-styled-jsx
 */
export const GlobalNoAuthStyle = () => (
  <style jsx global>{`
    *,
    :after,
    :before {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-tap-highlight-color: transparent;
    }
    body {
      letter-spacing: 0.5px;
      overflow-x: hidden;
      margin: 0;
      font-family: ${interFont};
      font-size: 14px;
      font-weight: 300;
      line-height: 1.5;
      text-align: left;
      background-color: #384fe5;
      background-image: linear-gradient(270deg, #0a184b, #384fe5);
      padding-top: 4.5rem;
      padding-bottom: 4.5rem;
      color: #fff;
      padding-left: 15px;
      padding-right: 15px;
    }

    @media print {
      h1,
      a,
      body {
        background-color: #fff !important;
        background-image: none !important;
        color: #000 !important;
      }
    }

    h1 {
      font-size: 40px;
      font-weight: bold;
      color: #fff;
      text-align: left;
      margin-bottom: 1.6rem;
    }
    a,
    a:hover,
    a:focus,
    a:active {
      color: #fff;
      text-decoration: none;
      font-weight: 400;
    }

    a:not(.btn):not(.nav-link):not(.card):hover {
      opacity: 0.5;
    }
    svg {
      width: 100%;
      height: auto;
    }
    /** @TODO limit the form styles to only where we need to have them allows signup form to look right without redoing the entire form */
    ${classBasedBasicFormStyles}

    form .error {
      color: ${basicTheme.palette.red};
    }
    div.field input:not([type='checkbox']),
    div.field select,
    div.member-search-view input:not([type='checkbox']),
    div.member-search-view select {
      color: ${basicTheme.palette.black};
      background-color: ${basicTheme.palette.inputBackground}!important;
    }
    div.field input:not([type='checkbox'])::placeholder,
    div.field select::placeholder,
    div.member-search-view input:not([type='checkbox'])::placeholder,
    div.member-search-view select::placeholder {
      color: ${basicTheme.palette.black};
    }

    .myhr-logo {
      max-width: 150px;
    }

    /** Autocomplete style overrides */
    .MuiAutocomplete-root {
      font: 300 14px Inter, sans-serif;
      background-clip: padding-box;
      border-radius: 4px;
      background-color: ${basicTheme.palette.white}!important;
      border: 0;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    .MuiAutocomplete-root .MuiFormLabel-root {
      color: ${basicTheme.palette.black};
    }
    .MuiAutocomplete-root .MuiInputLabel-root.Mui-focused {
      color: ${basicTheme.palette.black};
      margin-top: 8px;
    }
    .MuiAutocomplete-root .MuiInputBase-input,
    .MuiAutocomplete-root .MuiFormControl-root,
    .MuiAutocomplete-root .MuiInputBase-root {
      color: ${basicTheme.palette.black};
      border-radius: 4px;
      background-clip: padding-box;
      background-color: ${basicTheme.palette.white}!important;
    }
    .MuiAutocomplete-root .MuiOutlinedInput-notchedOutline {
      border-style: none;
    }
    .MuiAutocomplete-root .MuiAutocomplete-popupIndicator {
      color: ${basicTheme.palette.black};
    }
  `}</style>
)
