import { getFontGroup } from './createFontGroup'

// To add a new font group, follow these steps:
// 1. Define the font group in the tokens.json file under the "fontFamilies" key.
//    For example, to add a new font family named "NewFont", you would add:
//    "NewFont": {
//      "value": "NewFont",
//      "type": "fontFamilies"
//    }
// 2. In this file (Fonts/index.ts), import the getFontGroup function at the top:
//    import { getFontGroup } from "./createFontGroup";
// 3. Then, create a new constant for the font group using the getFontGroup function and export it:
//    export const NewFont = getFontGroup('NewFont');
// Now, you can use the NewFont constant in your components to apply the font family.

export const Inter = getFontGroup('inter')
