import Rollbar from 'rollbar'
import React, { ErrorInfo, ReactNode } from 'react'
import { isRedirection } from '@/utils/RedirectError'
import { datadogRum } from '@datadog/browser-rum'

export class ErrorBoundary extends React.Component<
  unknown,
  { error: Error | null; errorInfo: ErrorInfo | null }
> {
  rollbar: Rollbar
  constructor(props: unknown) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    if (isRedirection(error)) {
      error.doRedirect()
    }
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    })
    const renderingError = new Error(error.message)
    renderingError.name = `ReactRenderingError`
    renderingError.stack = errorInfo.componentStack
    ;(renderingError as unknown as typeof Error & { cause?: Error }).cause =
      error
    // You can also log error messages to an error reporting service here
    if (process.env.NODE_ENV === 'production') {
      datadogRum.addError(renderingError)
    } else {
      console.error(error, errorInfo)
    }
  }

  render(): ReactNode {
    if (this.state.errorInfo) {
      // @TODO basic layout
      return (
        <div>
          <h2>Something went wrong.</h2>
          {process.env.NODE_ENV === 'development' && (
            <details style={{ whiteSpace: 'pre-wrap' }}>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
            </details>
          )}
        </div>
      )
    }
    // Normally, just render children
    return this.props.children
  }
}
