import { useAskUs } from '../utils/useAskUs'
import * as React from 'react'
import { Fragment } from 'react'

export const AskUsWidget = (): JSX.Element => {
  // NOTE: while we include the widget via a hook, we wrap it in a component
  // so that we can conditionally include it on certain pages only (logged in ones)
  useAskUs()
  return <Fragment> </Fragment>
}
