import { MyHR } from '@/types/MyHR'
import { PermissionKey } from '@/types/Permission'
import { hasOneOfPermissions } from './hasOneOfPermissions'

/**
 * Does the currently logged in user have permission either by role or permission granted.
 *
 * If permissionKey not provided then we are checking for role is owner or admin other wise denying
 *
 * user.role = 'owner' OR user.role = 'admin' have all permissions.
 */
export function hasPermission(
  myhr: MyHR,
  permissionKey?: PermissionKey,
  allowManager = false
): boolean {
  return hasOneOfPermissions(
    myhr,
    permissionKey ? [permissionKey] : undefined,
    allowManager
  )
}
