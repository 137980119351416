import tokens from '@/tokens/tokens.json'

type FigmaRingShadow = {
  [key in
    | 'brand'
    | 'brand-shadow-sm'
    | 'brand-shadow-xs'
    | 'gray'
    | 'gray-secondary'
    | 'gray-shadow-sm'
    | 'gray-shadow-xs'
    | 'error'
    | 'error-shadow-xs']: string
}

const FALLBACK_SHADOW = '0px 0px 0px 0px #000000' // No shadow

export const buildFallbackShadow = (): FigmaRingShadow => {
  return {
    brand: FALLBACK_SHADOW,
    'brand-shadow-sm': FALLBACK_SHADOW,
    'brand-shadow-xs': FALLBACK_SHADOW,
    gray: FALLBACK_SHADOW,
    'gray-secondary': FALLBACK_SHADOW,
    'gray-shadow-sm': FALLBACK_SHADOW,
    'gray-shadow-xs': FALLBACK_SHADOW,
    error: FALLBACK_SHADOW,
    'error-shadow-xs': FALLBACK_SHADOW,
  }
}

const buildShadow = (): FigmaRingShadow => {
  const shadow = { ...buildFallbackShadow() }
  const shadowTokens = tokens.global['ring']

  for (const scale in shadowTokens) {
    if (shadow.hasOwnProperty(scale)) {
      if (shadowTokens[scale].value.length > 0) {
        shadow[scale as keyof FigmaRingShadow] = ''
        shadowTokens[scale].value.forEach((shad: any, i: number) => {
          shadow[scale as keyof FigmaRingShadow] += `${shad.x}px ${shad.y}px ${
            shad.blur
          }px ${shad.spread}px ${shad.color}${
            shadowTokens[scale].value.length <= i + 1 ? '' : ', '
          }`
        })
      } else {
        shadow[
          scale as keyof FigmaRingShadow
        ] = `${shadowTokens[scale].value.x}px ${shadowTokens[scale].value.y}px ${shadowTokens[scale].value.blur}px ${shadowTokens[scale].value.spread}px ${shadowTokens[scale].value.color}`
      }
    }
  }
  return shadow
}

export const RingShadows = buildShadow()
