import { SxProps, Theme } from '@mui/material'
import { flatten } from 'lodash'

/**
 * Use this instead of `sx={{ ...sx1, ...(Array.isArray(sx) ? sx : [sx]) }}` to
 * combine two `sx` props.
 *
 * Usage:
 * ```
 * sx={ sxCombine({ lineHeight: '20px' }, sx) }
 * ```
 */
export const sxCombine = (...sx: (SxProps<Theme> | false | undefined)[]) =>
  flatten(sx.filter(Boolean)) as SxProps<Theme>
