import tokens from '@/tokens/tokens.json'
import { capitalize, get } from 'lodash'
import { AllColors } from '../rawColors'

type FigmaComponentColors = {
  [key in 'bg' | 'border' | 'fg' | 'text']: FigmaComponentColor
}

type FigmaComponentColor = Record<string, string | Record<string, string>>

const buildFallbackColor = (): FigmaComponentColors => {
  return {
    bg: {},
    border: {},
    fg: {},
    text: {},
  }
}

const getColor = (tokenColor: string): string => {
  let componentColorName = `${capitalize(
    tokenColor.replace(/{/g, '').replace(/}/g, '')
  )}`

  // Hack around Error name because we don't want to clash with Reacts Error type so we need to rename to use ErrorColor from figma
  if (componentColorName.includes('Error')) {
    componentColorName = componentColorName.replace('Error', 'ErrorColor')
  }

  return get(
    AllColors,
    // {neutral.50} => Neutral.50
    componentColorName
  )
}

export const buildColors = (): FigmaComponentColors => {
  const colors = { ...buildFallbackColor() }
  const colorTokens = tokens.colour
  for (const component in colorTokens) {
    if (colors.hasOwnProperty(component)) {
      for (const type in colorTokens[component]) {
        if (typeof colorTokens[component][type].value === 'string') {
          colors[component as keyof FigmaComponentColors][type] = getColor(
            colorTokens[component][type].value
          )
        } else {
          for (const subComponent in colorTokens[component][type]) {
            colors[component][`${type}-${subComponent}`] = getColor(
              colorTokens[component][type][subComponent].value
            )
          }
        }
      }
    }
  }

  return colors
}

export const ComponentColors = buildColors()
