import * as React from 'react'
import warning from 'tiny-warning'

/**
 * Types of layout the project provides
 */
export type MyHRLayout = 'basic' | 'application'
type MyHRLayoutState = [
  MyHRLayout,
  React.Dispatch<React.SetStateAction<MyHRLayout>>
]
const Context = React.createContext<MyHRLayoutState>([
  'application',
  () => undefined,
])

/**
 * Context provider for using and updating the layout
 */
export const MyHRLayoutProvider: React.FC<{
  layout?: MyHRLayout
}> = ({ layout = 'application', children }) => {
  const state = React.useState<MyHRLayout>(layout)
  return <Context.Provider value={state}>{children}</Context.Provider>
}

/**
 * Hook providing access to the context state for selectedDraftPersonIds and a setter
 */
export const useLayout = (): MyHRLayoutState => {
  const context = React.useContext(Context)
  warning(!!context, 'Must be used within <MyHRLayoutProvider>')
  return context
}
