import Head from 'next/head'
import { BasicContainer } from './styled/BasicContainer'
import { Row, Column } from '../grid'
import { NoAuthLayout } from '@/components/noAuthComponents/styled/NoAuthLayout'
import { GlobalNoAuthStyle } from './GlobalNoAuthStyle'
import { Fragment } from 'react'
import { MyHRThemeProvider } from '../contexts/MyHRThemeProvider'
import { GTM } from '../GTM'

export function Custom500Page(): JSX.Element {
  return (
    <Fragment>
      <GlobalNoAuthStyle />
      <MyHRThemeProvider layout="basic">
        <Head>
          {/* Use minimum-scale=1 to enable GPU rasterization */}
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
          />
        </Head>
        <GTM>
          <NoAuthLayout>
            <Head>
              <title>500: Internal Server Error</title>
            </Head>
            <BasicContainer size="small">
              <p>Something has gone wrong unexpectedly.</p>
              <p>
                Please try again or get in touch{' '}
                <a href="mailto:help@myhr.works">help@myhr.works</a>
              </p>
              <Row>
                <Column small={12}>
                  <a className="button secondary" href="/">
                    Take me to the home page
                  </a>
                </Column>
              </Row>
            </BasicContainer>
          </NoAuthLayout>
        </GTM>
      </MyHRThemeProvider>
    </Fragment>
  )
}
