/**
 * Sets the focus to any field with a name attribute that matches the parameter
 * passed in.
 *
 * NB: Use `autoFocus` on a field if possible in preference to this function.
 *
 * NOTE: there is a timeout in this function to delay trying to select the element on screen
 * to allow for any rendering to complete.
 *
 *
 * Usage:
 *
 * ```
 * useEffect(() => focusField('fieldnameattribute'), [])
 * ```
 */
export const focusField = (name: string): (() => void) => {
  const timeout = setTimeout(() => {
    const tokenField = document && document.querySelector(`[name="${name}"]`)
    if (tokenField) {
      const tokenFieldAsInput = tokenField as HTMLInputElement
      tokenFieldAsInput.focus()
    }
  }, 100)
  return () => {
    window.clearTimeout(timeout)
  }
}
