import { FunctionComponent } from 'react'
import { FieldError } from 'react-hook-form'
import styled from '@/utils/styled'
import { basicTheme } from './basicTheme'

const FieldErrorMessage = styled('small')`
  color: #5ff;
`
type FieldWrapperProps = {
  error?: FieldError
  className?: string
  name: string
}
export let FieldWrapper: FunctionComponent<FieldWrapperProps> = ({
  children,
  error,
  className,
  name, // so we can match for particular errors
}) => (
  <div className={`field-${name} ${className}`}>
    {children}
    {error && (
      <FieldErrorMessage
        data-testid={`field-error--${name}`}
        role="alert"
        id={`error-${name}`}
      >
        {error.message || 'Required'}
      </FieldErrorMessage>
    )}
  </div>
)
FieldWrapper = styled(FieldWrapper)`
  margin-bottom: 1.15rem;

  input:not([type='checkbox']),
  select {
    display: block;
    width: 100%;
    height: 2.8rem;
    padding: 0 0.85rem;
    font: 300 14px 'ars-maquette-web', sans-serif;
    background-clip: padding-box;
    border-radius: 4px;
    border: 0;
    color: ${basicTheme.palette.black};
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &::placeholder {
      color: ${basicTheme.palette.black};
    }
  }
`
