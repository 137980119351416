import { PropsWithChildren } from 'react'
import { Optional } from 'utility-types'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import nz from 'date-fns/locale/en-NZ'

import { PageProps } from '@/types/multiple'

const defaultCountryIso = ['CA', 'US']

type Props = PropsWithChildren<Optional<Pick<PageProps, 'myhr'>>>

/**
 * This component provides the correct date format for the MUI-X date picker
 * based on the user's location.
 *
 * It is unclear exactly what this does as we specify the format in the date
 * picker.
 *
 * Only US/CA and NZ/AU locales are supported.
 *
 * @see https://mui.com/x/react-date-pickers/adapters-locale/
 * @see [MuiLocationProvider](./MuiLocalizationProvider.tsx) for MUI Lab
 * localisation.
 */
export const MuiXLocalizationProvider = ({ myhr, children }: Props) => (
  <LocalizationProvider
    dateAdapter={AdapterDateFns}
    adapterLocale={
      defaultCountryIso.includes(
        myhr?.person?.location?.country_iso ??
          myhr?.member?.billing_location_country_iso ??
          ''
      )
        ? undefined
        : nz
    }
  >
    {children}
  </LocalizationProvider>
)
