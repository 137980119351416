import { NextPageContext } from 'next'

export type RedirectParams = { ctx?: NextPageContext; nextUrl: string }
export function redirect({ ctx, nextUrl }: RedirectParams): void {
  // if server side (ctx.res indicates that)
  if (ctx?.res) {
    ctx.res
      .writeHead(302, {
        Location: `${process.env.BASE_URL}${nextUrl}`,
      })
      .end()
    // client side
  } else {
    window.location.href = nextUrl
  }
}
