import { Person } from '@/types/multiple'
import { add, isAfter } from 'date-fns'

export const isRecentStarter = (person: Person): boolean => {
  const { when_started } = person
  return (
    when_started &&
    isAfter(new Date(when_started), add(new Date(), { weeks: -2 }))
  )
}
