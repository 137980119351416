import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react'
import warning from 'tiny-warning'

const Context = createContext<[boolean, Dispatch<SetStateAction<boolean>>]>(
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  undefined!
)

/** Context provider sharing an idle state. */
export const IdleStateProvider = ({ children }: PropsWithChildren<unknown>) => {
  const state = useState(false)
  return <Context.Provider value={state}>{children}</Context.Provider>
}

/**
 * Exposes management of the idle state. Should only be used by `UserPing` which
 * sets up the idle timer.
 *
 * It may be possible to encapsulate the idle timer into this context provider,
 * but that would take extra work.
 *
 * @see UserPing
 * @see useIdleState
 */
export const useSetIdleState = () => {
  const context = useContext(Context)
  warning(!!context, 'Must be used within <IdleStateProvider>')
  return context[1]
}

/**
 * Exposes the idle state as part of the autologout mechanism.
 */
export const useIdleState = () => {
  const context = useContext(Context)
  warning(!!context, 'Must be used within <IdleStateProvider>')
  return context[0]
}
