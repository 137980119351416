import { FC } from 'react'
import DateAdapter from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'

/**
 * @deprecated Migrate to MUI X controls.
 */
export const MuiLocalizationProvider: FC = (props) => {
  return <LocalizationProvider dateAdapter={DateAdapter} {...props} />
}
