import { FunctionComponent, Fragment, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { PageProps } from '@/types/multiple'
import { getPkgVersion } from '@/utils/getPkgVersion'
const version = getPkgVersion()
interface PageEventProps extends Record<string, string> {
  event: string
  page: string
}

/**
 * @see https://developers.google.com/tag-platform/tag-manager/web/datalayer
 */
export const doGTMPageView = (url: string): PageEventProps => {
  const pageEvent: PageEventProps = {
    event: 'pageview',
    page: url,
  }
  window && window.dataLayer && window.dataLayer.push(pageEvent)
  return pageEvent
}

export const useGTM = () => {
  const [eventFired, setEventFired] = useState(false)

  const doGTMEvent = (eventName: string, payload = {}) => {
    if (!eventFired) {
      const GTMEvent = {
        event: eventName,
        ...payload,
      }
      if (window && window.dataLayer) {
        window.dataLayer.push(GTMEvent)
        setEventFired(true)
      }
    }
  }

  return { doGTMEvent }
}

/** Google Tag Manager support */
export const GTM: FunctionComponent<{ pageProps?: PageProps }> = ({
  children,
  pageProps,
}) => {
  const router = useRouter()
  useEffect(() => {
    // set myhr global like
    window.myhr = pageProps?.myhr || {}
    window.myhr.version = version

    const handleRouteChange = (url) => {
      doGTMPageView(url)
    }
    doGTMPageView(window?.location?.href)
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events, pageProps])
  return <Fragment>{children}</Fragment>
}
