import { hasStarted } from '@/person/utils/hasStarted'
import { isRecentStarter } from '@/person/utils/isRecentStarter'
import { Person } from '@/types/multiple'
import { MyHR } from '@/types/MyHR'
import { hasOnboarding } from '@/utils/hasOnboarding'
import { hasPermission } from '@/utils/hasPermission'
import { TranslationFunction } from '@/utils/useTranslation'

type OnboardingContext = 'SelfService' | 'ManagerViewing' | 'ManagerEditing'

/**
 * Status of onboarding for the person (which is context dependent)
 */
export const getOnboardingStatus = (
  person: Person | undefined,
  myhr: MyHR,
  context: OnboardingContext
): {
  isOnboarding: boolean
  onboardingStatus: Person['onboarding_plan_status']
} => {
  let onboardingStatus: Person['onboarding_plan_status']

  if (
    person &&
    // An inactive person can't be onboarding (but their manager can still see closed plans )
    (person.status === 'active' ||
      person.onboarding_plan_status === 'closed') &&
    hasOnboarding(myhr) &&
    ((context === 'ManagerEditing' && hasPermission(myhr, 'onboarding')) ||
      context !== 'ManagerEditing')
  ) {
    onboardingStatus = person.onboarding_plan_status
  }

  // In the manager context, a person is considered to be onboarding as long
  // as the person has an onboardingStatus defined
  const isOnboarding =
    context === 'SelfService'
      ? onboardingStatus === 'active' || onboardingStatus === 'closed'
      : Boolean(onboardingStatus)

  return { isOnboarding, onboardingStatus }
}

/**
 * In the given context, does the current user need to be prompted
 * to start an onboarding plan for this person?
 */
export const needsPromptToStartOnboardingPlan = (
  person: Person,
  myhr: MyHR,
  context: OnboardingContext
): boolean => {
  if (
    context === 'SelfService' ||
    person.status === 'inactive' ||
    !hasPermission(myhr, 'onboarding')
  ) {
    // Self service can't start their own plan so there's no point in prompting them!
    // Also, inactive people will never have a plan!
    return false
  }
  const { onboardingStatus } = getOnboardingStatus(person, myhr, context)
  return (
    onboardingStatus !== 'active' &&
    onboardingStatus !== 'draft' &&
    (!hasStarted(person) || isRecentStarter(person))
  )
}

/**
 *
 * Determine if the person needs to have their agreement signed
 * and if so, what the state description should be
 *
 * @param person
 * @param t used to translate wording (if not supplied, the prompt it NOT returned)
 * @returns
 */
export const getAgreementSignatureStatus = (
  person: Person,
  t: TranslationFunction | undefined
): {
  waitingForSignature: boolean
  prompt?: string
} => {
  // An inactive person will never sign or have their employer sign their agreement!
  if (person.status === 'inactive') {
    return { waitingForSignature: false }
  }

  const { current_agreement_status } = person

  const waitingForSignature =
    current_agreement_status === 'ready-for-member-signature' ||
    current_agreement_status === 'ready-for-person-signature'

  let prompt

  if (waitingForSignature && t) {
    prompt =
      current_agreement_status === 'ready-for-member-signature'
        ? `${t('Agreement')} awaiting your signature`
        : `${t('Agreement')} awaiting employee signature`
  }

  return { waitingForSignature, prompt }
}
