import { defaultLocale, Locale } from '@/types/Locale'
import { PageProps } from '@/types/multiple'

/**
 * Determine which locale by the context proirity
 *
 * 1. Person
 * 2. Member Details
 * 3. Default (NZ)
 */
export const determineLocale = (props: PageProps): Locale => {
  return (
    props.person?.location?.locale ||
    props.myhr.memberDetails?.location?.locale ||
    defaultLocale
  )
}
