import React, { FC } from 'react'
import { ErrorBoundary } from '@/errors/ErrorBoundary'
import { Locale } from '@/types/Locale'
import { TranslationProvider } from '@/utils/TranslationContext'
import { MyHRThemeProvider } from './MyHRThemeProvider'
import { SWRConfigurationProvider } from './SWRConfigurationProvider'
import { MuiLocalizationProvider } from './MuiLocalizationProvider'
import { PagePropsProvider } from './PagePropsProvider'
import { PageProps } from '@/types/multiple'
import { IdleStateProvider } from './IdleStateProvider'
import { MuiXLocalizationProvider } from './MuiXLocalizationProvider'

export type ProviderProps = {
  locale: Locale
  pageProps: PageProps
}

export const Provider: FC<ProviderProps> = ({
  children,
  locale,
  pageProps,
}) => {
  return (
    <ErrorBoundary>
      <SWRConfigurationProvider>
        <PagePropsProvider value={pageProps}>
          <TranslationProvider value={locale}>
            <MuiLocalizationProvider>
              <MuiXLocalizationProvider {...pageProps}>
                <MyHRThemeProvider>
                  <IdleStateProvider>{children}</IdleStateProvider>
                </MyHRThemeProvider>
              </MuiXLocalizationProvider>
            </MuiLocalizationProvider>
          </TranslationProvider>
        </PagePropsProvider>
      </SWRConfigurationProvider>
    </ErrorBoundary>
  )
}
