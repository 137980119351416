import Head from 'next/head'
import { BasicContainer } from './styled/BasicContainer'
import { NoAuthLayout } from '@/components/noAuthComponents/styled/NoAuthLayout'
import { GlobalNoAuthStyle } from './GlobalNoAuthStyle'
import { Fragment } from 'react'
import { MyHRThemeProvider } from '../contexts/MyHRThemeProvider'
import { GTM } from '../GTM'
import { ServerError } from '@/types/ServerError'

const isDevelopment = process.env.NODE_ENV === 'development'

export function ServerErrorPage({
  error,
}: {
  error: ServerError
}): JSX.Element {
  const { message, url, status, title = 'Error Occurred' } = error || {}
  const unexpected = status !== 403
  return (
    <Fragment>
      <GlobalNoAuthStyle />
      <MyHRThemeProvider layout="basic">
        <Head>
          {/* Use minimum-scale=1 to enable GPU rasterization */}
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
          />
        </Head>
        <GTM>
          <NoAuthLayout>
            <Head>
              <title>MyHR Server Error</title>
            </Head>
            <BasicContainer size="medium">
              <h1>{title}</h1>
              <p>{message || 'Something has gone wrong unexpectedly.'}</p>
              <h2>Further options</h2>
              <ul className="list-bullet">
                <li>
                  Go to{' '}
                  <a className="underline" href="/">
                    launchpad
                  </a>
                </li>
                {unexpected && (
                  <li>
                    If the problem persists, contact{' '}
                    <a className="underline" href="mailto:help@myhr.works">
                      help@myhr.works
                    </a>
                  </li>
                )}
              </ul>
              {isDevelopment && (url || status) && (
                <Fragment>
                  <h2>Developer details</h2>
                  <ul className="list-bullet">
                    {url && <li>Url: {url}</li>}
                    {status && <li>{`Status: ${status}`}</li>}
                  </ul>
                </Fragment>
              )}
            </BasicContainer>
          </NoAuthLayout>
        </GTM>
      </MyHRThemeProvider>
    </Fragment>
  )
}
