import { UserPermissions } from '@/types/multiple'
import { MyHRWithoutMemberSelected } from '@/types/MyHRWithoutMemberSelected'
import isUndefined from 'lodash/isUndefined'

function isLoggedInUser(myhr: MyHRWithoutMemberSelected) {
  return Boolean(myhr && myhr.isAuthenticated && myhr.user && myhr.permissions)
}

export function getUserRoleProps(myhr: MyHRWithoutMemberSelected): {
  isOwner: boolean
  isAdmin: boolean
  isManager: boolean
  isManagementUser: boolean
  isPersonActiveSelfServiceDepartmentUser: boolean
} {
  const userIsLoggedIn = isLoggedInUser(myhr)
  const isOwner = userIsLoggedIn && myhr.user.role === 'owner'
  const isAdmin = userIsLoggedIn && myhr.user.role === 'admin'
  const isManager = userIsLoggedIn && myhr.user.role === 'manager'
  const isManagementUser =
    userIsLoggedIn &&
    (myhr.user.role === 'manager' || myhr.user.role === 'owner')
  const isPersonActiveSelfServiceDepartmentUser = !!(
    userIsLoggedIn &&
    isManagementUser &&
    myhr.user.person_id &&
    myhr.person &&
    myhr.person.status === 'active' &&
    myhr.person.self_service
  )
  return {
    isOwner,
    isAdmin,
    isManager,
    isManagementUser,
    isPersonActiveSelfServiceDepartmentUser,
  }
}

export function userPermissions(
  myhr: MyHRWithoutMemberSelected
): UserPermissions {
  const userPermissions: UserPermissions = {}
  if (isLoggedInUser(myhr)) {
    userPermissions.manageDepartments = false
    userPermissions.manageReviewSettings = false
    if (myhr.user.role === 'manager') {
      // Make a template friendly data structure of userPermissions
      myhr.user.permissions.forEach(function (permissionId) {
        const p = myhr.permissions.find(({ id }) => id === permissionId)
        if (p) {
          userPermissions[p.key] = true
        }
      })
    } else if (myhr.user.role === 'owner' || myhr.user.role === 'admin') {
      myhr.permissions.forEach(function (p) {
        userPermissions[p.key] = true
      })
      userPermissions.manageDepartments = true
      userPermissions.canIncreaseHeadCount = true
      userPermissions.manageUsers = true
      userPermissions.updateCompanyDetails = true
      userPermissions.upgradePackage = myhr.member?.package_id === 1 // Trial account
      userPermissions.manageSettings = true
      userPermissions.manageAccount = true
      // NOTE: manageReviewSettings is not a permission at this stage - only owners and admin can do this
      userPermissions.manageReviewSettings = true
      userPermissions.securitySettings = true
    }
    if (
      !isUndefined(userPermissions.integrations) &&
      myhr.member &&
      !myhr.member.integrations_enabled
    ) {
      userPermissions.integrations = false
    }
    // @TODO move this featureflag, permission combination logic to api so the permission
    // comes back false in that case
    // if the the feature flag is off for the member the permission is implied
    // to be false i.e. not granted
    const integrationsNotEnabledForMember =
      !myhr.member || !myhr.member.integrations_enabled
    if (integrationsNotEnabledForMember) {
      userPermissions.integrations = false
    }
  }
  return userPermissions
}
