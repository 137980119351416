import { convertToV2Url } from './isV2Url'
import { UrlObject } from 'url'
import { useIsV2 } from './useIsV2'

/**
 * Ensure a link stays within /v2/ if the current window.location is on there.
 */
export const useConvertedToV2Url = (url?: string | UrlObject): string => {
  const urlAsString = typeof url === 'string' ? url : url?.pathname ?? ''
  const isV2 = useIsV2()
  return convertToV2Url(isV2, urlAsString.replace('/v2/', '/'))
}
