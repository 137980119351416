import css from 'styled-jsx/css'
import { basicTheme } from './basicTheme'

/**
 * Using styled-jsx approach to ensure css ordering is correct with other global styles via imports
 * We want global components to be last to allow overrides from those sheets
 *
 * NB: @emotion/react Global came before those external imported sheets
 *
 * @See https://nextjs.org/blog/styling-next-with-styled-jsx
 */
export const classBasedBasicFormStyles = css.global`
  form {
    margin-bottom: 2.3rem;
  }
  form .error {
    color: ${basicTheme.palette.cyan};
  }

  div.field input:not([type='checkbox']),
  div.field select,
  div.member-search-view input:not([type='checkbox']),
  div.member-search-view select {
    display: block;
    width: 100%;
    height: 2.8rem;
    padding: 0 0.85rem;
    font-weight: ${basicTheme.fontWeight.light}
    font-size: 14px;
    background-clip: padding-box;
    border-radius: ${basicTheme.borderRadius};
    background-color: ${basicTheme.palette.inputBlue}!important;
    border: 0;
    color: ${basicTheme.palette.white};
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  div.field input:not([type='checkbox'])::placeholder,
  div.field select::placeholder,
  div.member-search-view input:not([type='checkbox'])::placeholder,
  div.member-search-view select::placeholder {
    color: ${basicTheme.palette.white};
  }

  div.field input.member-search,
  div.member-search-view input.member-search {
    margin-bottom: 1rem;
  }

  div.field input:focus,
  div.field select:focus,
  div.member-search-view input:focus,
  div.member-search-view select:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(56, 79, 214, 0.25);
  }

  div.field input[type='checkbox'],
  div.member-search-view input[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
    border-radius: 5px;
    color: ${basicTheme.palette.black};
    height: 20px;
    width: 20px;
    z-index: 999;
    margin-left: 0;
  }
`
