export const en_NZ = 'en_NZ' as const
export const en_AU = 'en_AU' as const
export const en_CA = 'en_CA' as const
export const locales = [en_NZ, en_AU, en_CA] as const
export type Locale = typeof locales[number]
export const defaultLocale: Locale = en_NZ

export type CountryISO = 'NZ' | 'AU' | 'CA' | 'SG' | 'GB'
export const defaultCountryISO: CountryISO = 'NZ'
export type Timezone =
  | 'Pacific/Auckland'
  | 'Australia/Sydney'
  | 'Australia/Brisbane'
  | 'Australia/Adelaide'
  | 'Australia/Hobart'
  | 'Australia/Melbourne'
  | 'Australia/Perth'
  | 'Australia/Darwin'
  | 'Asia/Singapore'
  | 'Europe/London'
  | 'Canada/Mountain'
  | 'Canada/Atlantic'
  | 'Canada/Pacific'
  | 'Canada/Central'
  | 'Canada/Eastern'

export const flags: Record<Locale, string> = {
  en_AU: '🇦🇺',
  en_NZ: '🇳🇿',
  en_CA: '🇨🇦',
}
