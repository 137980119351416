/**
 * Step 1. Login to Fontawesome (https://fontawesome.com) using the credentials in 1Password (user:pete@simnix.co.nz).
 * Step 2. Follow instructions to set up the NPM token (the tokens in the snippet will be our ones).
 * @see https://fontawesome.com/docs/web/setup/packages#set-up-npm-token-for-all-projects
 */
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import {
  faChartLine,
  faGear,
  faLoader,
  faNewspaper as faNewspaperLight,
  faUser,
  faBell,
  faFile,
} from '@fortawesome/pro-light-svg-icons'
import {
  faSquareCheck,
  faCircleCheck,
  faCircleXmark,
  faSquareMinus,
  faCheck,
  faMinus,
  faCircle,
  faChevronDown,
  faCaretDown,
  faTriangleExclamation,
  faCircleInfo as faCircleInfoFilled,
  faBold,
  faFlag,
} from '@fortawesome/free-solid-svg-icons'
import {
  faCalendar,
  faCalendarPlus,
  faChevronRight,
  faChevronLeft,
  faCircleArrowLeft,
  faClockRotateLeft,
  faCopy,
  faEllipsis,
  faEllipsisVertical,
  faEnvelope,
  faEye,
  faFileArrowUp,
  faFileLines,
  faLocationDot,
  faMessages,
  faNewspaper,
  faNoteSticky,
  faPen,
  faPlus,
  faSparkles,
  faSmile,
  faSquare,
  faTags,
  faTrashCan,
  faUserPlus,
  faUser as faUserRegular,
  faXmark,
  faCircleInfo,
  faEyeSlash,
  faCircleExclamation,
  faArrowLeft,
  faArrowRight,
  faHouse,
  faBriefcase,
  faUserGroup,
  faListCheck,
  faTrophy,
  faClock,
  faFolders,
  faFolderClosed,
  faChartLine as faChartLineRegular,
  faPuzzlePiece,
  faChevronsLeft,
  faChevronsRight,
  faBars,
  faCircleQuestion,
  faGear as faGearRegular,
  faBell as faBellRegular,
  faBellRing,
  faAlarmClock,
  faArrowUpRightFromSquare,
  faStar,
  faFilePen,
  faFileSignature,
  faUserTie,
  faPersonBurst,
  faPersonCircleXmark,
  faPersonCircleMinus,
  faPersonCircleExclamation,
  faClipboardUser,
  faPersonWalkingArrowRight,
  faItalic,
  faUnderline,
  faAlignLeft,
  faAlignCenter,
  faAlignRight,
  faAlignJustify,
  faUpload,
  faArrowDownToLine,
  faHeadset,
  faMagnifyingGlass,
  faRightFromBracket,
  faGrid2,
  faCalendarLines,
  faCircleXmark as faCircleXmarkRegular,
  faBuilding,
} from '@fortawesome/pro-regular-svg-icons'

import styled from '@/utils/styled'
import AccountCircle from '@mui/icons-material/AccountCircle'
import AddRounded from '@mui/icons-material/AddRounded'
import AddBox from '@mui/icons-material/AddBox'
import AddCircleRounded from '@mui/icons-material/AddCircleRounded'
import ArchiveRounded from '@mui/icons-material/ArchiveRounded'
import ArrowRightAltRounded from '@mui/icons-material/ArrowRightAltRounded'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined'
import ChatRounded from '@mui/icons-material/ChatRounded'
import ChatBubbleOutlineRounded from '@mui/icons-material/ChatBubbleOutlineRounded'
import CheckCircleOutlineRounded from '@mui/icons-material/CheckCircleOutlineRounded'
import CheckCircleRounded from '@mui/icons-material/CheckCircleRounded'
import CheckRounded from '@mui/icons-material/CheckRounded'
import CloseRounded from '@mui/icons-material/CloseRounded'
import CloudUploadRounded from '@mui/icons-material/CloudUploadRounded'
import ContentCopyRounded from '@mui/icons-material/ContentCopyRounded'
import DeleteRounded from '@mui/icons-material/DeleteRounded'
import DescriptionRounded from '@mui/icons-material/DescriptionRounded'
import DragIndicatorRounded from '@mui/icons-material/DragIndicatorRounded'
import EditRounded from '@mui/icons-material/EditRounded'
import ErrorOutlineRounded from '@mui/icons-material/ErrorOutlineRounded'
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded'
import FileUpload from '@mui/icons-material/FileUpload'
import FindInPageRounded from '@mui/icons-material/FindInPageRounded'
import GetAppRounded from '@mui/icons-material/GetAppRounded'
import HelpRounded from '@mui/icons-material/HelpRounded'
import InventoryRounded from '@mui/icons-material/InventoryRounded'
import CircleOutlined from '@mui/icons-material/CircleOutlined'
import LaunchRounded from '@mui/icons-material/LaunchRounded'
import List from '@mui/icons-material/List'
import MenuRounded from '@mui/icons-material/MenuRounded'
import MoreVertRounded from '@mui/icons-material/MoreVertRounded'
import NavigateBeforeRounded from '@mui/icons-material/NavigateBeforeRounded'
import NavigateNextRounded from '@mui/icons-material/NavigateNextRounded'
import NotificationsRounded from '@mui/icons-material/NotificationsRounded'
import PersonAdd from '@mui/icons-material/PersonAdd'
import People from '@mui/icons-material/People'
import PersonRounded from '@mui/icons-material/PersonRounded'
import PriorityHighRounded from '@mui/icons-material/PriorityHighRounded'
import RefreshRounded from '@mui/icons-material/RefreshRounded'
import RemoveCircleOutlineRounded from '@mui/icons-material/RemoveCircleOutlineRounded'
import Rule from '@mui/icons-material/Rule'
import SearchRounded from '@mui/icons-material/SearchRounded'
import VerifiedRounded from '@mui/icons-material/VerifiedRounded'
import WarningRounded from '@mui/icons-material/WarningRounded'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import PersonSearch from '@mui/icons-material/PersonSearch'
import Reviews from '@mui/icons-material/Reviews'
import PostAdd from '@mui/icons-material/PostAdd'
import { CalendarToday } from '@mui/icons-material'
import Star from '@mui/icons-material/Star'
import StarRounded from '@mui/icons-material/StarRounded'
import ShuffleIcon from '@mui/icons-material/Shuffle'
import Undo from '@mui/icons-material/Undo'
import ConnectWithoutContact from '@mui/icons-material/ConnectWithoutContact'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { faFolderClosed as faFolderSolid } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faBullseyeArrow } from '@fortawesome/pro-regular-svg-icons'

/**
 * NOTE
 * ----
 * When using an icon designed in Figma, use the IconContainer as this will account for padding
 * that is used by the icon set in Figma (@link src/components/elements/Icons.tsx Line 148)
 *
 * FontAwesome uses font size to draw the size of its icons.
 *
 * @see Outlined borders in {@link https://www.figma.com/file/cCXkNW4mreuKXdfkNjUSAy/Design-System?type=design&node-id=1097-1337&mode=dev FontAwesome smiley icons on Figma}.
 * @see {@link https://github.com/MyHR/member-forms/pull/2579#discussion_r1410092749 Explanation with images on GitHub}
 */
const createFontAwesomeIcon =
  // eslint-disable-next-line react/display-name
  (icon: IconProp) => (props?: Omit<FontAwesomeIconProps, 'icon'>) =>
    <FontAwesomeIcon {...props} icon={icon} />

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const AccountCircleIcon = AccountCircle

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const AddIcon = AddRounded

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const AddBoxIcon = AddBox

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const AddCircleIcon = AddCircleRounded

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const AddPersonIcon = PersonAdd

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const AlarmClockIcon = createFontAwesomeIcon(faAlarmClock)

// export const AndroidIcon = AndroidRounded

// export const AppleIcon = Apple

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const ArchiveIcon = InventoryRounded

// @TODO Can't use this styled icon as a component prop
// export const ArchiveIcon = styled(Inventory2Rounded)`
//   transform: scale(0.9, 0.9);
//   transform-origin: center;
// `

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const ArrowDownIcon = styled(ArrowRightAltRounded)`
  transform: rotate(90deg);
`

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const ArrowUpIcon = styled(ArrowRightAltRounded)`
  transform: rotate(270deg);
`

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const ArrowLeftIcon = ArrowBackIcon

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const BuildingIcon = createFontAwesomeIcon(faBuilding)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const FAArrowLeftIcon = createFontAwesomeIcon(faArrowLeft)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const FAArrowRightIcon = createFontAwesomeIcon(faArrowRight)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const AnnouncementIcon = CampaignOutlinedIcon

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const CalendarIcon = CalendarToday

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const BlankCalendarIcon = createFontAwesomeIcon(faCalendar)
/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const AddCalendarIcon = createFontAwesomeIcon(faCalendarPlus)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const BullseyeArrow = createFontAwesomeIcon(faBullseyeArrow)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const BriefcaseIcon = createFontAwesomeIcon(faBriefcase)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const BarsIcon = createFontAwesomeIcon(faBars)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const BellIcon = createFontAwesomeIcon(faBell)
/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const BellRegularIcon = createFontAwesomeIcon(faBellRegular)
/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const BellRingIcon = createFontAwesomeIcon(faBellRing)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const BoldIcon = createFontAwesomeIcon(faBold)
/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const ItalicIcon = createFontAwesomeIcon(faItalic)
/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const UnderlineIcon = createFontAwesomeIcon(faUnderline)
/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const LeftAlignIcon = createFontAwesomeIcon(faAlignLeft)
/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const CenterAlignIcon = createFontAwesomeIcon(faAlignCenter)
/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const RightAlignIcon = createFontAwesomeIcon(faAlignRight)
/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const JustifyAlignIcon = createFontAwesomeIcon(faAlignJustify)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const CalendarLinesIcon = createFontAwesomeIcon(faCalendarLines)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const ChatIcon = styled(ChatRounded)`
  transform: scaleX(-1); // Flip horizontal
`

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const ChatBubble = ChatBubbleOutlineRounded

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const ChartLineIcon = createFontAwesomeIcon(faChartLine)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const ChartLineRegularIcon = createFontAwesomeIcon(faChartLineRegular)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const CaretDownIcon = createFontAwesomeIcon(faCaretDown)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const CheckIcon = CheckRounded

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const FaCheckIcon = createFontAwesomeIcon(faCheck)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const ChevronsLeftIcon = createFontAwesomeIcon(faChevronsLeft)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const ChevronsRightIcon = createFontAwesomeIcon(faChevronsRight)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const CircleCheckedIcon = CheckCircleOutlineRounded

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const CircleCheckedFilledIcon = CheckCircleRounded

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const CircleEmptyIcon = CircleOutlined

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const CircleErrorIcon = ErrorOutlineRounded

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const CircleInvalidIcon = RemoveCircleOutlineRounded

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const CirclePriorityIcon = ErrorOutlineRounded

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const CircleQuestionIcon = createFontAwesomeIcon(faCircleQuestion)

/**
 * "History" name is based on HistoryAction in performance review's OverviewCard,
 * which introduced this icon to the codebase.
 */
/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const ClipboardUserIcon = createFontAwesomeIcon(faClipboardUser)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const ClockRotateLeftIcon = createFontAwesomeIcon(faClockRotateLeft)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const ClockIcon = createFontAwesomeIcon(faClock)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const CloseIcon = CloseRounded

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const CollapseIcon = styled(ExpandMoreRounded)`
  transform: scaleY(-1); // Flip vertical
  transform-origin: center;
`

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const ConnectWithoutContactIcon = ConnectWithoutContact

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const CopyIcon = ContentCopyRounded

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const CopyIconFA = createFontAwesomeIcon(faCopy)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const DeleteIcon = styled(DeleteRounded)`
  transform: scale(0.95, 0.95);
  transform-origin: center;
`

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const DownloadIcon = GetAppRounded
/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const FAFileDownloadIcon = createFontAwesomeIcon(faArrowDownToLine)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const DownloadAllIcon = ArchiveRounded

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const DragIcon = DragIndicatorRounded

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const EditIcon = styled(EditRounded)`
  transform: scale(0.95, 0.95);
  transform-origin: center;
`

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const ExpandIcon = ExpandMoreRounded

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const ExternalLinkIcon = createFontAwesomeIcon(faArrowUpRightFromSquare)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const FileLightIcon = createFontAwesomeIcon(faFile)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const FileIcon = styled(DescriptionRounded)`
  transform: scale(0.95, 0.95);
  transform-origin: center;
`

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const FileUploadIcon = FileUpload
/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const FAFileUploadIcon = createFontAwesomeIcon(faUpload)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const FlagIcon = createFontAwesomeIcon(faFlag)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const GearIcon = createFontAwesomeIcon(faGear)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const GearRegularIcon = createFontAwesomeIcon(faGearRegular)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const EyeIcon = createFontAwesomeIcon(faEye)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const EyeSlashIcon = createFontAwesomeIcon(faEyeSlash)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const PenIcon = createFontAwesomeIcon(faPen)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const TrashIcon = createFontAwesomeIcon(faTrashCan)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const CircleArrowLeftIcon = createFontAwesomeIcon(faCircleArrowLeft)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const CircleCheckIcon = createFontAwesomeIcon(faCircleCheck)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const CircleExclamationIcon = createFontAwesomeIcon(faCircleExclamation)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const CircleXmarkIcon = createFontAwesomeIcon(faCircleXmark)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const CircleXmarkRegularIcon =
  createFontAwesomeIcon(faCircleXmarkRegular)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const Circle = createFontAwesomeIcon(faCircle)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const FileArrowUpIcon = createFontAwesomeIcon(faFileArrowUp)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const FilePenIcon = createFontAwesomeIcon(faFilePen)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const FileSignatureIcon = createFontAwesomeIcon(faFileSignature)

// todo: These icons are JSX.Elements (not functions that return JSX.Element)  Convert to functions?

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const XMarkIcon = createFontAwesomeIcon(faXmark)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const TagsIcon = <FontAwesomeIcon icon={faTags} />

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const NoteStickyIcon = createFontAwesomeIcon(faNoteSticky)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const ChevronRightIcon = createFontAwesomeIcon(faChevronRight)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const ChevronLeftIcon = createFontAwesomeIcon(faChevronLeft)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const ChevronDownIcon = createFontAwesomeIcon(faChevronDown)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const EmailIcon = createFontAwesomeIcon(faEnvelope)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const FileLinesIcon = createFontAwesomeIcon(faFileLines)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const FoldersIcon = createFontAwesomeIcon(faFolders)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const FolderIcon = createFontAwesomeIcon(faFolderClosed)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const FolderFilledIcon = createFontAwesomeIcon(faFolderSolid)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const GridIcon = createFontAwesomeIcon(faGrid2)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const HelpIcon = HelpRounded

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const HeadsetIcon = createFontAwesomeIcon(faHeadset)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const HouseIcon = createFontAwesomeIcon(faHouse)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const InfoIcon = createFontAwesomeIcon(faCircleInfo)
/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const CircleInfoFilledIcon = createFontAwesomeIcon(faCircleInfoFilled)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const LaunchIcon = styled(LaunchRounded)`
  transform: scale(0.9, 0.9);
  transform-origin: center;
`

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const ListIcon = List

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const LinkExternalIcon = styled(LaunchRounded)`
  width: 16px;
  height: 16px;
  margin: ${(props) => props.theme.spacing(0, 0, 0, 0.5)};
  color: inherit;
`

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const LocationIcon = createFontAwesomeIcon(faLocationDot)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const LoaderIcon = createFontAwesomeIcon(faLoader)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const LogoutIcon = createFontAwesomeIcon(faRightFromBracket)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const ListCheckIcon = createFontAwesomeIcon(faListCheck)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const MenuIcon = MenuRounded

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const Messages = createFontAwesomeIcon(faMessages)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const MoreIcon = createFontAwesomeIcon(faEllipsis)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const MinusIcon = createFontAwesomeIcon(faMinus)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const MoreVerticalIcon = createFontAwesomeIcon(faEllipsisVertical)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const SquareMinusIcon = createFontAwesomeIcon(faSquareMinus)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const OptionsIcon = MoreVertRounded

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const NavBackIcon = NavigateBeforeRounded

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const NavNextIcon = NavigateNextRounded

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const NewspaperLightIcon = createFontAwesomeIcon(faNewspaperLight)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const NewspaperIcon = createFontAwesomeIcon(faNewspaper)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const NotificationsIcon = NotificationsRounded

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const PersonIcon = PersonRounded

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const PersonIconFA = createFontAwesomeIcon(faUser)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const PeopleIconFA = createFontAwesomeIcon(faUserGroup)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const PersonIconRegularFA = createFontAwesomeIcon(faUserRegular)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const PersonAddIcon = createFontAwesomeIcon(faUserPlus)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const PersonBurstIcon = createFontAwesomeIcon(faPersonBurst)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const PersonXMarkIcon = createFontAwesomeIcon(faPersonCircleXmark)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const PersonCircleMinusIcon = createFontAwesomeIcon(faPersonCircleMinus)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const PersonCircleExclamation = createFontAwesomeIcon(
  faPersonCircleExclamation
)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const PersonWalkingRightIcon = createFontAwesomeIcon(
  faPersonWalkingArrowRight
)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const PlusIcon = createFontAwesomeIcon(faPlus)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const PuzzleIcon = createFontAwesomeIcon(faPuzzlePiece)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const PeopleIcon = People

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const PriorityIcon = PriorityHighRounded

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const QuestionIcon = createFontAwesomeIcon(faQuestionCircle)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const RefreshIcon = RefreshRounded

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const ReviewIcon = Reviews

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const SearchIcon = SearchRounded

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const FASearchIcon = createFontAwesomeIcon(faMagnifyingGlass)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const SearchFileIcon = FindInPageRounded

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const SearchPersonIcon = PersonSearch

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const ShuffleArrowIcon = ShuffleIcon

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const SmileIcon = createFontAwesomeIcon(faSmile)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const SparklesIcon = createFontAwesomeIcon(faSparkles)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const SquareCheckIcon = createFontAwesomeIcon(faSquareCheck)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const SquareIcon = createFontAwesomeIcon(faSquare)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const StarIcon = Star

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const FAStarIcon = createFontAwesomeIcon(faStar)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const StarRoundedIcon = StarRounded

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const StartOnboardingIcon = PostAdd

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const SuccessIcon = CheckCircleRounded

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const TaskListIcon = Rule

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const TriangleExclamationIcon = createFontAwesomeIcon(
  faTriangleExclamation
)
/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const TrophyIcon = createFontAwesomeIcon(faTrophy)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const UndoIcon = Undo

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const UserTieIcon = createFontAwesomeIcon(faUserTie)

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const UploadIcon = CloudUploadRounded

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const VerifiedIcon = VerifiedRounded

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const WarningIcon = styled(WarningRounded)`
  transform: scale(1.1, 1.1);
  transform-origin: center;
`

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const VisbilityIcon = Visibility

/** @deprecated Use [IconsV2](./figmaElements/IconsV2.tsx) */
export const VisibilityOffIcon = VisibilityOff
