import styled from '@/utils/styled'
import {
  Button as ButtonUnstyled,
  ButtonProps as MuiButtonProps,
} from '@mui/base'
import { ReactNode } from 'react'
import { LaunchIcon } from '@/components/elements/Icons'

/**
 * Using ButtonUnstyled here to avoid having to override mui styles but get the benefits of:
 *
 * - allowing all mui properties
 * - filtering mui properties
 * - mui WAI-ARIA implementation
 *
 * Middle ground between a html element button and full Mui Button
 */
const DefaultButton = styled(ButtonUnstyled)`
  display: inline-block;
  margin: 0;
  padding: ${(props) => props.theme.spacing(1.5, 2.5)};
  background: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: none !important;
  font-family: inherit;
  font-weight: 500;
  // @TODO theme.fontSize.default
  font-size: 1rem;
  text-transform: none;
  transition: all 0.2s ease;
  cursor: pointer;

  &[disabled] {
    background-color: transparent !important;
    color: ${(props) => props.theme.palette.text.disabled} !important;
    cursor: not-allowed !important;
  }

  &.button-small {
    padding: ${(props) => props.theme.spacing(1, 1.5)};
    // @TODO theme.fontSize.small
    font-size: 90%;
  }

  &.button-large {
    padding: ${(props) => props.theme.spacing(2, 3.5)};
    // @TODO theme.fontSize.large
    font-size: 110%;
  }

  &.button-with-icon {
    display: flex;
    align-items: center;
    gap: ${(props) => props.theme.spacing(1)};
  }
`

const ThinButton = styled(DefaultButton)`
  padding-left: ${(props) => props.theme.spacing(1.5)};
  padding-right: ${(props) => props.theme.spacing(1.5)};

  &.button-small {
    padding-left: ${(props) => props.theme.spacing(1)};
    padding-right: ${(props) => props.theme.spacing(1)};
  }

  &.button-large {
    padding-left: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
  }
`

/**
 * Contained Variant (Default)
 */

export const ContainedPrimaryButton = styled(DefaultButton)`
  background-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) =>
    props.theme.palette.getContrastText(props.theme.palette.text.primary)};

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.dark};
  }

  &[disabled] {
    background-color: ${(props) =>
      props.theme.palette.secondary.light} !important;
  }
`

const ContainedSecondaryButton = styled(DefaultButton)`
  background-color: ${(props) => props.theme.palette.secondary.main};
  color: ${(props) =>
    props.theme.palette.getContrastText(props.theme.palette.text.primary)};

  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.dark};
  }

  &[disabled] {
    background-color: ${(props) =>
      props.theme.palette.secondary.light} !important;
  }
`

const ContainedWarningButton = styled(DefaultButton)`
  background-color: ${(props) => props.theme.palette.warning.main};
  color: ${(props) =>
    props.theme.palette.getContrastText(props.theme.palette.text.primary)};

  &:hover {
    background-color: ${(props) => props.theme.palette.warning.dark};
  }

  &[disabled] {
    background-color: ${(props) =>
      props.theme.palette.secondary.light} !important;
  }
`

/**
 * Outlined Variant
 */

const OutlinedPrimaryButton = styled(DefaultButton)`
  border-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.main};

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.light};
    color: ${(props) => props.theme.palette.primary.dark};
  }

  &[disabled] {
    border-color: ${(props) => props.theme.palette.secondary.light} !important;
  }
`

const OutlinedSecondaryButton = styled(DefaultButton)`
  border-color: ${(props) => props.theme.palette.secondary.main};
  color: ${(props) => props.theme.palette.secondary.main};

  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.light};
    color: ${(props) => props.theme.palette.secondary.dark};
  }

  &[disabled] {
    border-color: ${(props) => props.theme.palette.secondary.light} !important;
  }
`

const OutlinedWarningButton = styled(DefaultButton)`
  border-color: ${(props) => props.theme.palette.warning.main};
  color: ${(props) => props.theme.palette.warning.main};

  &:hover {
    background-color: ${(props) => props.theme.palette.warning.light};
    color: ${(props) => props.theme.palette.warning.dark};
  }

  &[disabled] {
    border-color: ${(props) => props.theme.palette.secondary.light} !important;
  }
`

/**
 * Text Variant
 */

const TextPrimaryButton = styled(ThinButton)`
  color: ${(props) => props.theme.palette.primary.main};

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.light};
    color: ${(props) => props.theme.palette.primary.dark};
  }
`

const TextSecondaryButton = styled(ThinButton)`
  color: ${(props) => props.theme.palette.secondary.main};

  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.light};
    color: ${(props) => props.theme.palette.secondary.dark};
  }
`

const TextWarningButton = styled(ThinButton)`
  color: ${(props) => props.theme.palette.warning.main};

  &:hover {
    background-color: ${(props) => props.theme.palette.warning.light};
    color: ${(props) => props.theme.palette.warning.dark};
  }
`

const COLORS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  WARNING: 'warning',
} as const

const VARIANTS = {
  CONTAINED: 'contained',
  OUTLINED: 'outlined',
  TEXT: 'text',
} as const

const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
} as const

export type ButtonProps = Omit<MuiButtonProps, 'color' | 'variant'> & {
  color?: typeof COLORS[keyof typeof COLORS]
  variant?: typeof VARIANTS[keyof typeof VARIANTS]
  size?: typeof SIZES[keyof typeof SIZES]
  hasIcon?: boolean
}

export const Button = ({
  color = COLORS.PRIMARY,
  variant = VARIANTS.CONTAINED,
  size = SIZES.MEDIUM,
  hasIcon = false,
  ...rest
}: ButtonProps): JSX.Element => {
  const classNames: string[] = []
  classNames.push('button-' + size)
  if (hasIcon) {
    classNames.push('button-with-icon')
  }

  const componentMap = {
    contained: {
      primary: ContainedPrimaryButton,
      secondary: ContainedSecondaryButton,
      warning: ContainedWarningButton,
    },
    outlined: {
      primary: OutlinedPrimaryButton,
      secondary: OutlinedSecondaryButton,
      warning: OutlinedWarningButton,
    },
    text: {
      primary: TextPrimaryButton,
      secondary: TextSecondaryButton,
      warning: TextWarningButton,
    },
  }

  const Component = componentMap[variant][color]
  return (
    <Component
      className={classNames.join(' ')}
      color={color}
      variant={variant}
      {...rest}
    />
  )
}

export const ExternalButton = ({ children, ...rest }) => {
  return (
    <Button {...rest} hasIcon={true}>
      {children}
      <LaunchIcon />
    </Button>
  )
}

export const PagingButton = styled(Button)`
  width: 3rem;
  min-width: auto;
  padding-left: 0;
  padding-right: 0;
`

export type SavingButtonProps = ButtonProps & {
  submitting?: boolean
  children?: ReactNode
  submittingChildren?: ReactNode
  onClick?: (any) => void
  type?: 'button' | 'submit'
}

export const SavingButton = ({
  submitting,
  children = 'Save',
  submittingChildren = 'Saving...',
  onClick,
  type = 'submit',
  ...rest
}: SavingButtonProps): JSX.Element => (
  <Button type={type} disabled={submitting} onClick={onClick} {...rest}>
    {submitting ? submittingChildren : children}
  </Button>
)

export const DraftingButton = styled(SavingButton)`
  background-color: ${(props) => props.theme.palette.grey[600]};
  float: right;

  &:hover {
    background-color: ${(props) => props.theme.palette.grey[700]};
  }

  &[disabled]{
    border-color: ${(props) => props.theme.palette.secondary.light};
  }

  &:focus{
    background-color: ${(props) => props.theme.palette.grey[600]}};
  }
`

export const EmptyStateButton = styled(DefaultButton)`
  border-color: ${(props) => props.theme.palette.secondary.light};  
  border-radius: 8px;
  font-weight: 400;
  gap: 8px;

  &:hover {
    background-color: ${(props) => props.theme.palette.grey[200]};
  }

  &:focus{
    background-color: ${(props) => props.theme.palette.grey[100]}};
  }
`
