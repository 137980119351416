import { useState, useEffect } from 'react'

/**
 * Return the current `window.location.pathname` before and after mounting,
 * so that once we know it (after mounting), we can use it to determine other URLs.
 */
export const useCurrentUrl = (): string => {
  // use state to store current url using router pathname for server side and
  // window.location.pathname for client side
  const [currentUrl, setCurrentUrl] = useState<string>('')
  useEffect(() => {
    setCurrentUrl(window.location.pathname)
  }, [])
  return currentUrl
}
