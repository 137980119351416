/**
 * Our common interface to react-foundation grid classes components
 * If we ever want to swap these out it will be easier by
 * having our components come through this file.
 */
import { FC } from 'react'

/**
 * @deprecated switch to `<Grid container>` from `@mui/material/Grid`
 *
 * Custom implementation to react-foundation classes that we are using.
 */
export const Row: FC<{ className?: string; isCollapsed?: boolean }> = ({
  children,
  className,
  isCollapsed = false,
}) => (
  <div
    className={`row${className ? ` ${className}` : ''}${
      isCollapsed ? ' collapse' : ''
    }`}
  >
    {children}
  </div>
)
/**
 * @deprecated switch to `<Grid item>` from `@mui/material/Grid`d
 *
 * Custom implementation to react-foundation classes that we are using.
 */
export const Column: FC<{
  className?: string
  small?: number
  medium?: number
  large?: number
}> = ({ children, className, small, medium, large }) => (
  <div
    className={`columns${className ? ` ${className}` : ''}${
      small ? ` small-${small}` : ''
    }${medium ? ` medium-${medium}` : ''}${large ? ` large-${large}` : ''}`}
  >
    {children}
  </div>
)
