import tokens from '@/tokens/tokens.json'

type FigmaShadow = {
  [key in 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl']: string
}

const FALLBACK_SHADOW = '0px 0px 0px 0px #000000' // No shadow

export const buildFallbackShadow = (): FigmaShadow => {
  return {
    xs: FALLBACK_SHADOW,
    sm: FALLBACK_SHADOW,
    md: FALLBACK_SHADOW,
    lg: FALLBACK_SHADOW,
    xl: FALLBACK_SHADOW,
    '2xl': FALLBACK_SHADOW,
    '3xl': FALLBACK_SHADOW,
  }
}

const buildShadow = (): FigmaShadow => {
  const shadow = { ...buildFallbackShadow() }
  const shadowTokens = tokens.global['shadow']

  for (const scale in shadowTokens) {
    if (shadow.hasOwnProperty(scale)) {
      if (shadowTokens[scale].value.length > 0) {
        shadow[scale as keyof FigmaShadow] = ''
        shadowTokens[scale].value.forEach((shad: any, i: number) => {
          shadow[scale as keyof FigmaShadow] += `${shad.x}px ${shad.y}px ${
            shad.blur
          }px ${shad.spread}px ${shad.color}${
            shadowTokens[scale].value.length <= i + 1 ? '' : ', '
          }`
        })
      } else {
        shadow[
          scale as keyof FigmaShadow
        ] = `${shadowTokens[scale].value.x}px ${shadowTokens[scale].value.y}px ${shadowTokens[scale].value.blur}px ${shadowTokens[scale].value.spread}px ${shadowTokens[scale].value.color}`
      }
    }
  }
  return shadow
}

export const BoxShadows = buildShadow()
