import tokens from '@/tokens/tokens.json'

/**
 * May have noticed that some of the tones don't exist in `tokens.json`.
 *
 * Leaving options open as we may introduce more colours,
 * so we shouldn't need to touch this if it ever changes in Figma.
 *
 * `default` does not exist in `tokens.json`
 * but is introduced when building colours,
 * so that we know what the fallback value of a colour is.
 */
type FigmaColor = {
  [key in
    | '25'
    | '50'
    | '100'
    | '150'
    | '200'
    | '250'
    | '300'
    | '350'
    | '400'
    | '450'
    | '500'
    | '550'
    | '600'
    | '650'
    | '700'
    | '750'
    | '800'
    | '850'
    | '900'
    | '950'
    | 'default']: string // Not imported value. Just to help devs know the fallback colour
}

const FIGMA_COLOR_TONE_AMOUNT = 38 // The maximum number of color tones a Figma color can have

const FALLBACK_COLOR = '#50ffc0' // An obscure colour to tell us when we are using the wrong tone

export const buildFallbackColor = (): FigmaColor => {
  return Array.from(
    { length: FIGMA_COLOR_TONE_AMOUNT },
    (_, i) => `${25 * (i + 1)}` as const
  ).reduce((acc, key) => ({ ...acc, [key]: FALLBACK_COLOR }), {
    default: FALLBACK_COLOR,
  } as FigmaColor)
}

export const buildColor = (colorName: string): FigmaColor => {
  const color = { ...buildFallbackColor() }
  const colorTokens = tokens.global[colorName]

  for (const scale in colorTokens) {
    if (color.hasOwnProperty(scale)) {
      color[scale as keyof FigmaColor] = colorTokens[scale].value
    }
  }

  color.default = color['500']
  return color
}

// To add a new color:
// 1. Define a constant for the color using the buildColor function
//    Example: export const NewColor = buildColor('New color');
//    This must match the name for the color from the Figma design tokens in tokens.json
// 2. Use the color constant wherever you need to apply the new color
//    Example: <div style={{ color: NewColor['500'] }}>Hello, World!</div>

export const Blue = buildColor('blue')
export const Neutral = buildColor('neutral')
export const Success = buildColor('success')
export const Warning = buildColor('warning')
export const ErrorColor = buildColor('error')
export const Info = buildColor('info')
export const AllColors = {
  Blue,
  Neutral,
  Success,
  Warning,
  ErrorColor,
  Info,
}
