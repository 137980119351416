import { Spacing } from '@/components/theme/styles/rawSpacing'
import { Inter } from '@/components/theme/styles/typography/Fonts'
import {
  Button as MUIButton,
  SxProps,
  Theme,
  ButtonProps as MuiButtonProps,
} from '@mui/material'
import { ComponentColors } from '@/components/theme/styles/colors'
import { PropsWithChildren, ReactNode, Ref } from 'react'
import { BoxShadows } from '@/components/theme/styles/shadow'
import { RingShadows } from '@/components/theme/styles/ringShadow'
import { sxCombine } from '@/components/sxCombine'

export type MuiColor =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'quaternary'
  | 'link'

export type ButtonProps = Omit<
  MuiButtonProps,
  'size' | 'variant' | 'color' | 'type'
> & {
  /** `color` is based on the MUI naming convention. */
  color?: MuiColor
  /**
   * `variant` is an imitation of MUI's style to suit our own design.
   * We are calculating the MUI variant based on color.
   */
  variant?: 'brand' | 'grey'
  /**
   * Overrides `color` to modify the focus shadow.
   */
  focusShadowColor?: MuiColor
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
  /** Override `size`. */
  height?: number
  type?: 'standard' | 'destructive'
  startIcon?: JSX.Element
  endIcon?: JSX.Element
  showEndIconOnHover?: boolean
  children?: ReactNode
  sx?: SxProps<Theme>
  disabled?: boolean
  Icon?: React.ElementType
  isActive?: boolean
  /**
   * Added to avoid using `React.forwardRef` for {@link ./ControlledDatePicker.tsx}.
   *
   * @see {@link https://stackoverflow.com/a/65756885 How do I avoid 'Function components cannot be given refs' when using react-router-dom?}
   */
  muiRef?: Ref<HTMLButtonElement>
}

const getBackgroundColor = ({
  type,
  color,
  variant,
}: Pick<ButtonProps, 'type' | 'color' | 'disabled' | 'variant'>) => {
  if (color === 'primary') {
    return type === 'destructive'
      ? ComponentColors.bg['error-solid']
      : ComponentColors.bg['brand-solid']
  } else if (color === 'secondary') {
    if (variant === 'grey') {
      return type === 'destructive'
        ? ComponentColors.bg.primary
        : ComponentColors.bg.primary
    } else if (variant === 'brand') {
      return ComponentColors.bg['brand-secondary']
    }
  } else if (color === 'tertiary') {
    if (variant === 'grey') {
      return 'transparent'
    } else if (variant === 'brand') {
      // @Todo Anuka 22/11/2023: No design yet
    }
  } else if (color === 'link') {
    return 'transparent'
  }
}

const getHoverBackgroundColours = ({
  type,
  color,
  variant,
}: Pick<ButtonProps, 'type' | 'color' | 'variant'>) => {
  if (color === 'primary') {
    return type === 'destructive'
      ? ComponentColors.bg['error-hover']
      : ComponentColors.bg['brand-hover']
  } else if (color === 'secondary') {
    return type === 'destructive'
      ? ComponentColors.bg['error-primary']
      : variant === 'brand'
      ? ComponentColors.bg['brand-primary']
      : ComponentColors.bg['primary-hover']
  } else if (color === 'tertiary') {
    return type === 'destructive'
      ? // @todo (Liam-MyHR, 2023-11-23) Destructive variant for secondary button is not designed yet.
        ComponentColors.bg['error-primary']
      : ComponentColors.bg['primary-hover']
  } else if (color === 'quaternary') {
    return type === 'destructive'
      ? // @todo (Liam-MyHR, 2023-11-23) Destructive variant for secondary button is not designed yet.
        ComponentColors.bg['error-primary']
      : ComponentColors.bg['primary-hover']
  } else if (color === 'link') {
    return 'transparent'
  }
}

const getDisabledBackgroundColours = ({
  color,
}: Pick<ButtonProps, 'type' | 'color'>) => {
  switch (color) {
    case 'primary':
      return ComponentColors.bg.disabled
    case 'secondary':
      return ComponentColors.bg.primary
    case 'tertiary':
      return 'transparent'
    case 'quaternary':
      return 'transparent'
    case 'link':
    default:
      return 'transparent'
  }
}

const getForegroundColors = ({
  type,
  color,
  variant,
}: Pick<ButtonProps, 'type' | 'color' | 'variant'>) => {
  if (color === 'primary') {
    return ComponentColors.fg.inverse
  } else if (color === 'secondary') {
    if (variant === 'grey') {
      return type === 'destructive'
        ? ComponentColors.fg['error-primary']
        : ComponentColors.fg.secondary
    } else if (variant === 'brand') {
      return ComponentColors.fg.brand
    }
  } else if (color === 'tertiary') {
    if (variant === 'grey') {
      return type === 'destructive'
        ? ComponentColors.fg['error-primary']
        : ComponentColors.fg.tertiary
    } else if (variant === 'brand') {
      return type === 'destructive'
        ? ComponentColors.fg['error-primary']
        : ComponentColors.fg.tertiary
    }
  } else if (color === 'quaternary') {
    if (variant === 'grey') {
      return type === 'destructive'
        ? ComponentColors.fg['error-primary']
        : ComponentColors.fg.quaternary
    } else if (variant === 'brand') {
      return type === 'destructive'
        ? ComponentColors.fg['error-primary']
        : ComponentColors.fg.quaternary
    }
  } else if (color === 'link') {
    if (variant === 'grey') {
      return ComponentColors.text.tertiary
    } else {
      return type === 'destructive'
        ? ComponentColors.fg['error-primary']
        : ComponentColors.fg.brand
    }
  }
  return 'inherit'
}

const getHoverForegroundColors = ({
  type,
  color,
  variant,
}: Pick<ButtonProps, 'type' | 'color' | 'variant'>) => {
  if (color === 'primary') {
    return ComponentColors.fg.inverse
  } else if (color === 'secondary') {
    if (variant === 'grey') {
      return type === 'destructive'
        ? ComponentColors.fg['error-primary']
        : ComponentColors.fg['secondary-hover']
    } else if (variant === 'brand') {
      return ComponentColors.fg.brand
    }
  } else if (color === 'tertiary') {
    // Destructive buttons don't have variants, as of 2023-11-23.
    if (type === 'destructive') {
      return ComponentColors.fg['error-hover']
    }
    if (variant === 'grey') {
      return ComponentColors.fg['tertiary-hover']
    } else if (variant === 'brand') {
      // @todo (myhr-chman, 2023-11-23) No design yet.
    }
  } else if (color === 'quaternary') {
    // Destructive buttons don't have variants, as of 2023-11-23.
    if (type === 'destructive') {
      return ComponentColors.fg['error-hover']
    }
    if (variant === 'grey') {
      return ComponentColors.fg['quarternary-hover']
    } else if (variant === 'brand') {
      // @todo (myhr-chman, 2023-11-23) No design yet.
    }
  } else if (color === 'link') {
    if (variant === 'grey') {
      return ComponentColors.text['tertiary-hover']
    } else {
      return type === 'destructive'
        ? ComponentColors.fg['error-hover']
        : ComponentColors.fg['brand-hover']
    }
  }
  return 'inherit'
}

const getFocusBoxShadow = ({
  color,
  type,
}: Pick<ButtonProps, 'type' | 'color'>) => {
  // Destructive buttons don't have variants, as of 2023-11-23.
  if (type === 'destructive') {
    return RingShadows['error-shadow-xs']
  }
  if (color === 'primary') {
    return RingShadows['brand-shadow-xs']
  }
  if (color === 'secondary') {
    return RingShadows['brand-shadow-xs']
  }
  if (color === 'tertiary' || color === 'quaternary') {
    return RingShadows['gray-shadow-xs']
  }
  if (color === 'link') {
    return 'none'
  }
}
const getBoxShadow = ({ color }: Pick<ButtonProps, 'color'>) => {
  if (color === 'primary') {
    return BoxShadows.xs
  }
  if (color === 'secondary') {
    return BoxShadows.xs
  }
  if (color === 'tertiary') {
    return 'none'
  }
  if (color === 'quaternary') {
    return 'none'
  }
  if (color === 'link') {
    return 'none'
  }
}

const getBorderColor = ({
  type,
  color,
  variant,
}: Pick<ButtonProps, 'type' | 'color' | 'variant'>) => {
  if (color === 'primary') {
    return 'none'
  }
  if (color === 'secondary') {
    if (variant === 'grey') {
      return type === 'destructive'
        ? `1px solid ${ComponentColors.border['error']}`
        : `1px solid ${ComponentColors.border.primary}`
    }
    if (variant === 'brand') {
      return `1px solid ${ComponentColors.border.brand}`
    }
    return 'none'
  }
  if (color === 'tertiary') {
    return 'none'
  }
  if (color === 'quaternary') {
    return 'none'
  }
  if (color === 'link') {
    return 'none'
  }
}
const getDisabledBorderColor = ({
  color,
}: Pick<ButtonProps, 'type' | 'color' | 'variant'>) => {
  if (color === 'secondary') {
    return `1px solid ${ComponentColors.border['disabled-subtle']}`
  }
  return 'none'
}

export const Button = ({
  color = 'primary',
  variant = 'grey',
  type = 'standard',
  size = 'md',
  height,
  focusShadowColor,
  isActive = false,
  disabled,
  startIcon,
  endIcon,
  showEndIconOnHover = false,
  sx,
  children,
  Icon,
  muiRef,
  ...rest
}: PropsWithChildren<ButtonProps>) => {
  const muiVariant =
    color === 'primary'
      ? 'contained'
      : color === 'secondary' ||
        color === 'tertiary' ||
        color === 'quaternary' ||
        color === 'link'
      ? 'text'
      : 'outlined' // Clear mistake happened somewhere here.

  const fontSize =
    size === 'xs'
      ? Inter['text xs']['semibold']
      : size === 'sm' || size === 'md'
      ? Inter['text sm']['semibold']
      : size === 'lg' || size === 'xl'
      ? Inter['text md']['semibold']
      : size === '2xl'
      ? Inter['text lg']['semibold']
      : 'inherit'

  const paddingX =
    color === 'link'
      ? 0
      : size === 'xs'
      ? 10
      : size === 'sm'
      ? 12
      : size === 'md'
      ? 14
      : size === 'lg'
      ? 16
      : size === 'xl'
      ? 18
      : 22

  const paddingY =
    color === 'link'
      ? 0
      : size === 'xs'
      ? 4
      : size === 'sm'
      ? 8
      : size === 'md'
      ? 10
      : size === 'lg'
      ? 10
      : size === 'xl'
      ? 12
      : 14

  const contentHeight =
    size === 'sm'
      ? 20
      : size === 'md'
      ? 20
      : size === 'lg'
      ? 24
      : size === 'xl'
      ? 24
      : 28

  // Gap between start and end icon and the text, includes 2px padding on each
  // side of the text.
  const iconPadding =
    size === 'xs' || size === 'sm' || size === 'md'
      ? 6
      : size === 'lg' || size === 'xl'
      ? 8
      : 12

  const iconSize = size !== '2xl' ? 20 : 24

  const customSx = sxCombine(
    color !== 'link' && {
      height: height ? `${height}px` : `${contentHeight + 2 * paddingY}px`,
      // @TODO Why are min/max here? Should `height` work?
      minHeight: height ? `${height}px` : `${contentHeight + 2 * paddingY}px`,
      maxHeight: height ? `${height}px` : `${contentHeight + 2 * paddingY}px`,
    },
    {
      gap: `${iconPadding}px`,
      fontSize: fontSize,
      boxShadow: isActive
        ? getFocusBoxShadow({ type, color: focusShadowColor ?? color })
        : getBoxShadow({ color }),
      border: getBorderColor({ type, color, variant }),
      borderRadius: Icon ? 2 : Spacing.spacing[100],
      paddingX: `${paddingX}px`,
      paddingY: `${paddingY}px`,
      backgroundColor: getBackgroundColor({ type, color, variant }),
      color: getForegroundColors({ type, color, variant }),
      '&': {
        textTransform: 'none',
      },
      '&:hover': {
        backgroundColor: getHoverBackgroundColours({ type, color, variant }),
        color: getHoverForegroundColors({ type, color, variant }),
        boxShadow: isActive
          ? getFocusBoxShadow({ type, color: focusShadowColor ?? color })
          : 'none',
        '.MuiButton-endIcon': {
          display: 'inherit',
        },
      },
      '&:disabled': {
        backgroundColor: getDisabledBackgroundColours({ color }),
        color: ComponentColors.fg.disabled,
        border: getDisabledBorderColor({ color }),
      },
      // This shows when the button is focused by keyboard.
      '&:focus-visible': {
        boxShadow: getFocusBoxShadow({
          type,
          color: focusShadowColor ?? color,
        }),
      },
      '&:focus:not(:hover)': {
        backgroundColor:
          getBackgroundColor({ type, color, variant }) ?? 'transparent',
        color: getForegroundColors({ type, color, variant }),
      },
      '.MuiButton-startIcon': {
        justifyContent: 'center',
        alignItems: 'center',
        width: `${iconSize}px`,
        height: `${iconSize}px`,
        mx: 0, // Override MUI's default margin since using `gap` for spacing.
        svg: {
          width: `${iconSize - 4}px`,
          height: `${iconSize - 4}px`,
        },
      },
      '.MuiButton-endIcon': {
        display: showEndIconOnHover ? 'none' : 'inherit',
        justifyContent: 'center',
        alignItems: 'center',
        width: `${iconSize}px`,
        height: `${iconSize}px`,
        mx: 0, // Override MUI's default margin since using `gap` for spacing.
        svg: {
          width: `${iconSize - 4}px`,
          height: `${iconSize - 4}px`,
        },
      },
      minWidth: 'max-content',
      textTransform: 'none',
    },
    sx
  )

  if (Icon && customSx) {
    const buttonSize = height
      ? `${height}px`
      : size === 'sm'
      ? '36px'
      : size === 'md'
      ? '40px'
      : size === 'lg'
      ? '44px'
      : size === 'xl'
      ? '48px'
      : size === '2xl'
      ? '56px'
      : '1000px' // Too big and will break the button

    customSx[0]['width'] = buttonSize
    customSx[0]['height'] = buttonSize
    customSx[0]['minWidth'] = buttonSize
    customSx[0]['minHeight'] = buttonSize
    customSx[0]['maxWidth'] = buttonSize
    customSx[0]['maxHeight'] = buttonSize

    if (customSx[1]) {
      delete customSx[1]['minWidth'] // Remove max-content override.
    }

    const iconOnlySizeWidth =
      size === 'sm'
        ? '16px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '19.2px'
        : size === 'xl'
        ? '16px'
        : size === '2xl'
        ? '19px'
        : '1000px' //Too big and will break the button

    const iconOnlySizeHeight =
      size === 'sm'
        ? '16px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '18.24px'
        : size === 'xl'
        ? '16px'
        : size === '2xl'
        ? '19px'
        : '1000px' //Too big and will break the button

    children = (
      <Icon style={{ width: iconOnlySizeWidth, height: iconOnlySizeHeight }} />
    )
  }

  return (
    <MUIButton
      startIcon={startIcon}
      endIcon={endIcon}
      variant={muiVariant}
      disabled={disabled}
      disableFocusRipple
      disableRipple
      sx={customSx}
      {...rest}
      ref={muiRef}
    >
      {children}
    </MUIButton>
  )
}
