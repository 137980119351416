import styled from '@/utils/styled'

export const BasicContainer = styled('div')<{
  size?: 'small' | 'medium' | 'large' | 'xlarge'
}>`
  background-color: transparent;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
  margin: 0 auto;
  max-width: ${(props) =>
    props.size === 'small'
      ? '350px'
      : props.size === 'medium'
      ? '480px'
      : props.size === 'large'
      ? '550px'
      : props.size === 'xlarge'
      ? '720px'
      : '640px'};
`
