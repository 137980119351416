import tokens from '@/tokens/tokens.json'

export type FigmaValues = Record<string, string>

const buildFallbackValues = (
  FALLBACK_VALUE: string,
  globalKey: string
): FigmaValues =>
  Object.keys(tokens.global[globalKey]).reduce(
    (acc, key) => ({ ...acc, [key]: FALLBACK_VALUE }),
    {} as FigmaValues
  )

const buildValues = (
  FALLBACK_VALUE: string,
  globalKey: string,
  unitType = ''
): FigmaValues => {
  const values = { ...buildFallbackValues(FALLBACK_VALUE, globalKey) }
  const valueTokens = tokens.global[globalKey]

  Object.keys(valueTokens).forEach((i) => {
    if (values.hasOwnProperty(i)) {
      values[i as keyof FigmaValues] = valueTokens[i].value + unitType
    }
  })
  return values
}

export const createValues = (
  FALLBACK_VALUE: string,
  globalKey: string,
  unitType?: string
) => buildValues(FALLBACK_VALUE, globalKey, unitType)
