import React, { FunctionComponent } from 'react'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import MuiTheme from '../theme'
import { SnackbarProvider } from 'notistack'
import { MyHRLayout, MyHRLayoutProvider } from './useLayout'

// NB: not doing CssBaseline for Mui as we already have a baseline

// @TODO why is snackbarprovider in this Theme provider??

export const MyHRThemeProvider: FunctionComponent<{ layout?: MyHRLayout }> = ({
  layout = 'application',
  children,
}) => (
  <MuiThemeProvider theme={MuiTheme}>
    <MyHRLayoutProvider layout={layout}>
      <SnackbarProvider
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
      >
        {children}
      </SnackbarProvider>
    </MyHRLayoutProvider>
  </MuiThemeProvider>
)
