import { defaultLocale, Locale } from '@/types/Locale'
import React, { ProviderProps } from 'react'

const TranslationContext = React.createContext<Locale>(defaultLocale)

export const TranslationProvider = (args: ProviderProps<Locale>) => (
  <TranslationContext.Provider {...args} />
)
TranslationProvider.displayName = 'TranslationProvider'
export const TranslationConsumer = TranslationContext.Consumer
export default TranslationContext
